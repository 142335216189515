import { useForm } from "antd/lib/form/Form";
import { useCallback } from "react";

import {
  useGetRecruitmentQuery,
  useUpdateRecruitmentMutation
} from "~api/constants";
import { CONSTANTS_KEYS } from "~api/constants/consts";
import { RecruitmentDocument } from "~api/constants/types";

const useScoredSubjects = () => {
  const [form] =
    useForm<Pick<RecruitmentDocument, "scoredSubjects">>();
  const { mutate, isLoading: isSubmitting } =
    useUpdateRecruitmentMutation({
      merge: true
    });

  const { data: recruitmentData, isLoading } = useGetRecruitmentQuery(
    {
      staleTime: 0,
      cacheTime: 0,
      select: (snapshot) => snapshot.data()
    }
  );

  const onSubmit = useCallback(
    (data: Pick<RecruitmentDocument, "scoredSubjects">) => {
      mutate({
        id: `${CONSTANTS_KEYS.Recruitment}`,
        scoredSubjects: {
          additional: data.scoredSubjects.additional,
          additionalSubjectsLimit:
            +data.scoredSubjects.additionalSubjectsLimit,
          required: data.scoredSubjects.required
        }
      });
    },
    [mutate]
  );

  return { form, isSubmitting, recruitmentData, isLoading, onSubmit };
};

export default useScoredSubjects;
