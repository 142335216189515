import Spin from "antd/lib/spin";

import ClassProfileForm from "../partials/form";

import { useEditClassProfile } from "./hooks";

const NewClassProfilePage = () => {
  const { isLoading, classProfileLoading, initialValues, onSubmit } =
    useEditClassProfile();

  if (classProfileLoading) return <Spin size="large" />;

  return (
    <ClassProfileForm
      editMode
      {...{ isLoading, onSubmit, initialValues }}
    />
  );
};

export default NewClassProfilePage;
