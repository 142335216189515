import { FIRESTORE_COLLECTIONS } from "~setup/consts/firestoreCollections";

import {
  makeFirestoreCollectionMutationHook,
  makeFirestoreDocumentDeletionHook,
  makeFirestoreQueryHook
} from "../utils";

import { Administrator } from "./types";

const useGetAdministrators = makeFirestoreQueryHook<Administrator>(
  FIRESTORE_COLLECTIONS.Administrators
);

const useCreateAdministratorMutation =
  makeFirestoreCollectionMutationHook<Administrator>(
    FIRESTORE_COLLECTIONS.Administrators
  );

const useAdministratorDeletion = makeFirestoreDocumentDeletionHook(
  FIRESTORE_COLLECTIONS.Administrators
);

export {
  useGetAdministrators,
  useCreateAdministratorMutation,
  useAdministratorDeletion
};
