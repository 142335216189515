import { FormattedMessage } from "react-intl";
import Layout from "antd/lib/layout/layout";
import Title from "antd/lib/typography/Title";
import Spin from "antd/lib/spin";

const AuthLoader = () => (
  <Layout className="auth-loader-container">
    <Title>
      <FormattedMessage defaultMessage="Uwierzytelnianie użytkownika..." />
    </Title>
    <Spin size="large" />
  </Layout>
);

export default AuthLoader;
