import Form from "antd/lib/form";
import InputNumber from "antd/lib/input-number";
import Title from "antd/lib/typography/Title";
import { FormattedMessage } from "react-intl";

import useCommonRules from "~hooks/useCommonRules";

import { useGrades } from "./hooks";

const Grades: React.FC = () => {
  const grades = useGrades();
  const { required } = useCommonRules();

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Title level={4}>
        <FormattedMessage defaultMessage="Punkty z ocen" />
      </Title>
      {grades.map((grade) => (
        <Form.Item
          name={["grades", grade.key]}
          key={grade.key}
          label={grade.label}
          labelCol={{ sm: 16 }}
          rules={[required]}
        >
          <InputNumber
            defaultValue={grade.default}
            min={0}
            max={100}
            step={1}
            precision={0}
          />
        </Form.Item>
      ))}
    </div>
  );
};

export default Grades;
