import { addDoc, getDocs, query } from "firebase/firestore";

import { ClassDocument } from "~api/classes/types";
import { Teacher } from "~api/teachers/types";
import { makeCollectionRef } from "~api/utils";
import { FIRESTORE_COLLECTIONS } from "~setup/consts/firestoreCollections";

import { LibrusClass, WithRef } from "../types";

const importClasses = async (
  librusClasses: LibrusClass[],
  teachers: WithRef<Teacher>[]
): Promise<WithRef<WithId<ClassDocument>>[]> => {
  const ref = makeCollectionRef<WithId<ClassDocument>>(
    FIRESTORE_COLLECTIONS.Classes
  );

  const { docs } = await getDocs(query(ref));

  const existingClasses =
    docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
      ref: doc.ref
    })) || [];

  const newClasses = await librusClasses.reduce(
    async (prev, current) => {
      const awaitedPrev = await prev;

      if (
        existingClasses.findIndex(
          (existingClass) => existingClass.librusId === current.id
        ) >= 0
      ) {
        return awaitedPrev;
      }

      const newClassData: ClassDocument = {
        name: current.name,
        librusId: current.id,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        supervisor: teachers.find(
          (teacher) => teacher.librusId === current.teacherid
        )?.ref
      };

      const newClassReference = await addDoc(ref, newClassData);

      return [
        ...awaitedPrev,
        {
          ...newClassData,
          ref: newClassReference,
          id: newClassReference.id
        } as WithRef<WithId<ClassDocument>>
      ];
    },
    Promise.resolve<WithRef<WithId<ClassDocument>>[]>([])
  );

  return [...existingClasses, ...newClasses];
};

export default importClasses;
