import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import notification from "antd/lib/notification";
import { doc } from "firebase/firestore";

import { queryClient } from "~providers/react-query/consts";
import { FIRESTORE_COLLECTIONS } from "~setup/consts/firestoreCollections";
import {
  useGetSubjectQuery,
  useUpdateSubjectMutation
} from "~api/subjects";
import { Firestore } from "~setup/lib/firebase";

import { SubjectFormValues } from "../partials/form/types";

const useEditSubject = () => {
  const { id } = useParams();
  const { formatMessage } = useIntl();
  const { isLoading: isSubjectLoading, data: subjectData } =
    useGetSubjectQuery(id, {
      enabled: !!id,
      select: (snapshot) => ({
        name: snapshot.data()?.name ?? "",
        shortcut: snapshot.data()?.shortcut ?? "",
        teacherRefs:
          snapshot
            .data()
            ?.teachers.map((teacherDoc) => teacherDoc.path) || []
      })
    });

  const { mutateAsync, isLoading } = useUpdateSubjectMutation();

  const onUpdate = useCallback(
    async (formData: SubjectFormValues) => {
      if (!id) return;

      await mutateAsync({
        id,
        name: formData.name,
        shortcut: formData.shortcut,
        teachers: formData.teacherRefs.map((teacherRef) =>
          doc(Firestore, teacherRef)
        )
      });

      await queryClient.invalidateQueries(
        FIRESTORE_COLLECTIONS.Subjects
      );

      notification.success({
        message: formatMessage({
          defaultMessage: "Dane zostały zaktualizowane"
        })
      });
    },
    [formatMessage, id, mutateAsync]
  );

  return { isSubjectLoading, subjectData, onUpdate, isLoading };
};

export { useEditSubject };
