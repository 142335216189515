import { useCallback } from "react";
import { useIntl } from "react-intl";
import notification from "antd/lib/notification";
import { doc } from "firebase/firestore";

import { Firestore } from "~setup/lib/firebase";
import { queryClient } from "~providers/react-query/consts";
import { FIRESTORE_COLLECTIONS } from "~setup/consts/firestoreCollections";
import { useCreateClassProfileMutation } from "~api/classProfiles";

import { ClassProfileFormValues } from "../partials/form/types";

const useNewClassProfile = () => {
  const { formatMessage } = useIntl();
  const { mutateAsync, isLoading } = useCreateClassProfileMutation();

  const onSubmit = useCallback(
    async (data: ClassProfileFormValues) => {
      await mutateAsync({
        name: data.name,
        extendedSubjects: (data.extendedSubjectsRefs ?? []).map(
          (ref) => doc(Firestore, ref)
        ),
        additionalSubjects: (data.additionalSubjectsRefs ?? []).map(
          (ref) => doc(Firestore, ref)
        ),
        languageGroups: (data.languageGroups ?? []).map(
          (languageGroup) => ({
            languages: (languageGroup?.languageRefs ?? []).map(
              (ref) => doc(Firestore, ref)
            )
          })
        )
      });

      await queryClient.invalidateQueries(
        FIRESTORE_COLLECTIONS.ClassProfiles
      );

      notification.success({
        message: formatMessage({
          defaultMessage: "Profil klasy został dodany"
        })
      });
    },
    [formatMessage, mutateAsync]
  );

  return { onSubmit, isLoading };
};

export { useNewClassProfile };
