import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { memo } from "react";
import { v4 } from "uuid";

import Button from "~components/Button";
import { useTimetableField } from "~providers/timetable/hooks";
import { Lesson } from "~api/timetable/types";

import LessonPicker from "../LessonPicker";

import { TimeSlotProps } from "./types";

const TimeSlot: React.FC<TimeSlotProps> = ({ path }) => {
  const { value: lessons = [], setValue } = useTimetableField<
    Lesson[]
  >(path, []);

  return (
    <div className="timetable-slot-wrapper">
      {lessons?.map((lesson, id) => (
        <div className="timetable-lesson-wrapper" key={lesson.id}>
          <Button
            danger
            className="timetable-lesson-delete-btn"
            size="small"
            shape="circle"
            ghost={false}
            icon={<DeleteOutlined />}
            onClick={() => {
              setValue(lessons.filter((l) => l.id !== lesson.id));
            }}
          />
          <LessonPicker path={`${path}[${id}]`} />
        </div>
      ))}
      <Button
        icon={<PlusOutlined />}
        className="timetable-add-btn"
        ghost={false}
        onClick={() => setValue([...lessons, { id: v4() }])}
      >
        <FormattedMessage
          tagName="span"
          defaultMessage="Dodaj zajęcia"
        />
      </Button>
    </div>
  );
};

export default memo(TimeSlot);
