import React from "react";

import TeacherForm from "../partials/form";

import { useNewTeacher } from "./hooks";

const NewTeacherPage: React.FC = () => {
  const { onSubmit, isLoading } = useNewTeacher();

  return <TeacherForm {...{ onSubmit, isLoading }} />;
};

export default NewTeacherPage;
