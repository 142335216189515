import React from "react";
import Spin from "antd/lib/spin";

import ClassForm from "../partials/form";

import { useEditClass } from "./hooks";

const EditClassPage: React.FC = () => {
  const { isClassLoading, classData, isLoading, onUpdate } =
    useEditClass();

  if (isClassLoading) return <Spin size="large" />;

  return (
    <ClassForm
      editMode
      onSubmit={onUpdate}
      initialValues={classData}
      {...{ isLoading }}
    />
  );
};

export default EditClassPage;
