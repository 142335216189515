import { useIntl } from "react-intl";
import { Table, Typography } from "antd";

import Button from "~components/Button";
import useTableLocale from "~hooks/useTableLocale";

import { useAdministrators } from "./hooks";
import AdministratorFormModal from "./partials/AdministratorFormModal";

const AdministratorsListPage = () => {
  const { formatMessage } = useIntl();
  const {
    columns,
    dataSource,
    loading,
    isFormOpen,
    openForm,
    closeForm,
    onAddAdministrator
  } = useAdministrators();
  const locale = useTableLocale();

  return (
    <>
      <div className="list-header">
        <Typography.Title>
          {formatMessage({ defaultMessage: "Administratorzy" })}
        </Typography.Title>
        <Button onClick={openForm}>
          {formatMessage({ defaultMessage: "Dodaj administratora" })}
        </Button>
      </div>
      <Table
        rowKey="id"
        {...{ columns, loading, dataSource, locale }}
      />
      <AdministratorFormModal
        isVisible={isFormOpen}
        onCancel={closeForm}
        onSuccess={onAddAdministrator}
      />
    </>
  );
};

export default AdministratorsListPage;
