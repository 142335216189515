import { getDoc, QueryDocumentSnapshot } from "firebase/firestore";

import {
  ClassDocument,
  ClassQuerySnapshot
} from "~api/classes/types";

const resolveClassDocument = async (
  classDocument: QueryDocumentSnapshot<ClassDocument>
) => {
  const classData = classDocument.data();
  const supervisor = await getDoc(classData.supervisor);

  return {
    id: classDocument.id,
    name: classData.name,
    supervisor: supervisor.data()
  };
};

const transformClasses = async (snapshot: ClassQuerySnapshot) =>
  Promise.all(snapshot.docs.map(resolveClassDocument));

export { resolveClassDocument, transformClasses };
