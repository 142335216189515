import { useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";
import Space from "antd/lib/space";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { ColumnType } from "antd/lib/table";
import { orderBy } from "firebase/firestore";

import Button from "~components/Button";
import { ROUTES } from "~router/consts";
import {
  useGetSubjectsQuery,
  useSubjectDeletion
} from "~api/subjects";
import { Subject } from "~api/subjects/types";
import useConfirmModal from "~hooks/useConfirmModal";

const useSubjects = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { data: dataSource = [], isLoading } = useGetSubjectsQuery<
    WithId<Subject>[]
  >({
    queryConstrains: [orderBy("name")],
    select: (snapshot) =>
      (snapshot?.docs ?? []).map((subject) => ({
        id: subject.id,
        teachers: [],
        name: subject.data().name,
        shortcut: subject.data().shortcut
      })) || []
  });
  const { mutate: deleteSubject } = useSubjectDeletion();
  const showConfirmModal = useConfirmModal();

  const onDeleteClick = useCallback(
    ({ id, name }: WithId<Subject>) => {
      showConfirmModal({
        onOk: () => deleteSubject({ id }),
        title: formatMessage(
          {
            defaultMessage:
              "Czy na pewno chcesz usunąć przedmiot {name}?"
          },
          { name }
        )
      });
    },
    [formatMessage, deleteSubject, showConfirmModal]
  );

  const onEditClick = useCallback(
    (id: string) => {
      const path = generatePath(ROUTES.EditSubject, { id });

      navigate(path);
    },
    [navigate]
  );

  const columns: ColumnType<WithId<Subject>>[] = useMemo(
    () => [
      {
        title: formatMessage({ defaultMessage: "Nazwa przedmiotu" }),
        dataIndex: "name",
        key: "name",
        sorter: (a, b) => a.name.localeCompare(b.name)
      },
      {
        title: formatMessage({ defaultMessage: "Skrót" }),
        dataIndex: "shortcut",
        key: "shortcut",
        width: 200,
        sorter: (a, b) =>
          (a.shortcut || "").localeCompare(b.shortcut || ""),
        render: (value) => value || "-"
      },
      {
        title: formatMessage({ defaultMessage: "Operacje" }),
        key: "action",
        width: 200,
        render: (_, item) => (
          <Space size="middle">
            <Button
              icon={<EditOutlined />}
              onClick={() => onEditClick(item.id)}
            >
              <FormattedMessage
                tagName="span"
                defaultMessage="Edytuj"
              />
            </Button>
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => onDeleteClick(item)}
            >
              <FormattedMessage
                tagName="span"
                defaultMessage="Usuń"
              />
            </Button>
          </Space>
        )
      }
    ],
    [formatMessage, onDeleteClick, onEditClick]
  );

  return {
    loading: isLoading,
    columns,
    dataSource
  };
};

export { useSubjects };
