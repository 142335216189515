import { useForm } from "antd/lib/form/Form";
import { useCallback, useMemo } from "react";
import {
  useGetRecruitmentQuery,
  useUpdateRecruitmentMutation
} from "~api/constants";
import { CONSTANTS_KEYS } from "~api/constants/consts";
import { RecruitmentDocument } from "~api/constants/types";

import { useGrades } from "./partials/Grades/hooks";
import { useMultipliers } from "./partials/Multipliers/hooks";

const usePoints = () => {
  const multipliers = useMultipliers();
  const grades = useGrades();

  const [form] =
    useForm<Pick<RecruitmentDocument, "multipliers" | "grades">>();

  const { data: recruitmentData, isLoading } = useGetRecruitmentQuery(
    {
      staleTime: 0,
      cacheTime: 0,
      select: (snapshot) => snapshot.data()
    }
  );

  const initialValues = useMemo(
    () => ({
      grades: grades.reduce(
        (prev, current) => ({
          ...prev,
          [current.key]:
            recruitmentData?.grades?.[current.key] ?? current.default
        }),
        {}
      ),
      multipliers: multipliers.reduce(
        (prev, current) => ({
          ...prev,
          [current.key]:
            recruitmentData?.multipliers?.[current.key] ??
            current.default
        }),
        {}
      )
    }),
    [
      grades,
      multipliers,
      recruitmentData?.grades,
      recruitmentData?.multipliers
    ]
  );

  const { mutate, isLoading: isSubmitting } =
    useUpdateRecruitmentMutation({
      merge: true
    });

  const onSubmit = useCallback(
    (data: Pick<RecruitmentDocument, "multipliers" | "grades">) => {
      mutate({
        id: `${CONSTANTS_KEYS.Recruitment}`,
        multipliers: data.multipliers,
        grades: data.grades
      });
    },
    [mutate]
  );

  return { form, initialValues, onSubmit, isLoading, isSubmitting };
};

export { usePoints };
