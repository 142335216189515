import { useCallback } from "react";
import { useIntl } from "react-intl";
import notification from "antd/lib/notification";

import { useCreateTeacherMutation } from "~api/teachers";
import { queryClient } from "~providers/react-query/consts";
import { FIRESTORE_COLLECTIONS } from "~setup/consts/firestoreCollections";

import { TeacherFormValues } from "../partials/form/types";

const useNewTeacher = () => {
  const { formatMessage } = useIntl();
  const { mutateAsync, isLoading } = useCreateTeacherMutation();

  const onSubmit = useCallback(
    async (data: TeacherFormValues) => {
      await mutateAsync({
        firstName: data.firstName,
        lastName: data.lastName,
        shortcut: data.shortcut
      });

      await queryClient.invalidateQueries(
        FIRESTORE_COLLECTIONS.Teachers
      );

      notification.success({
        message: formatMessage({
          defaultMessage: "Nauczyciel został dodany"
        })
      });
    },
    [formatMessage, mutateAsync]
  );

  return { onSubmit, isLoading };
};

export { useNewTeacher };
