import { useIntl } from "react-intl";

import { useAuth } from "~providers/auth/context";

import useConfirmModal from "./useConfirmModal";

const useLogoutModal = () => {
  const { formatMessage } = useIntl();
  const { signOut } = useAuth();

  return useConfirmModal({
    onOk: signOut,
    title: formatMessage({
      defaultMessage: "Czy na pewno chcesz się wylogować?"
    })
  });
};

export default useLogoutModal;
