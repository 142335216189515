import { FormProps } from "antd";
import { useForm, useWatch } from "antd/lib/form/Form";
import { doc } from "firebase/firestore";
import { useMemo } from "react";

import { useGetClassroomsQuery } from "~api/classrooms";
import { useGetSubjectsQuery } from "~api/subjects";
import { Subject } from "~api/subjects/types";
import { useGetTeachersQuery } from "~api/teachers";
import { Teacher } from "~api/teachers/types";
import { Lesson, LessonDocument } from "~api/timetable/types";
import { makeCollectionRef } from "~api/utils";
import { useTimetableField } from "~providers/timetable/hooks";
import { CLASSROOM_DOC_ID } from "~setup/consts/environment";
import { FIRESTORE_COLLECTIONS } from "~setup/consts/firestoreCollections";

import { LessonFormValues } from "./types";

const useLessonPicker = (path: string) => {
  const [form] = useForm<LessonFormValues>();
  const { value: lesson, setValue } =
    useTimetableField<LessonDocument>(`${path}`);

  const { isLoading: subjectsLoading, data: subjects = [] } =
    useGetSubjectsQuery({
      select: (snapshot) =>
        snapshot.docs.map((subjectDoc) => ({
          id: subjectDoc.id,
          name: subjectDoc.data().name,
          teachersIds: subjectDoc.data().teachers.map((t) => t.id)
        }))
    });

  const { isLoading: teachersLoading, data: teachers = [] } =
    useGetTeachersQuery({
      select: (snapshot) =>
        snapshot.docs.map((teacherDoc) => ({
          id: teacherDoc.id,
          name: `${teacherDoc.data().firstName} ${
            teacherDoc.data().lastName
          }`
        }))
    });

  const { isLoading: classroomsLoading, data: classrooms = [] } =
    useGetClassroomsQuery(CLASSROOM_DOC_ID, {
      select: (snapshot) => snapshot.data()?.rooms || []
    });

  const selectedSubjectId = useWatch<string>("subjectRefId", form);

  const selectedSubject = subjects.find(
    (subject) => subject.id === selectedSubjectId
  );

  const availableTeachers = teachers.filter((teacher) =>
    selectedSubject?.teachersIds.includes(teacher.id)
  );

  const onChange: FormProps["onFieldsChange"] = (_, fields) => {
    const newValues: LessonFormValues = fields.reduce(
      (prev, current) => {
        const name = (
          Array.isArray(current.name) ? current.name[0] : current.name
        ) as keyof Lesson;

        return {
          ...prev,
          [name]:
            ((current.errors || []).length > 0
              ? lesson?.[name]
              : current.value) || null
        };
      },
      { id: lesson.id }
    );

    if (newValues.subjectRefId !== lesson.subject?.id) {
      newValues.teacherRefId = undefined;

      form.setFieldsValue({
        teacherRefId: undefined
      });
    }

    const lessonDocument: LessonDocument = {
      id: newValues.id,
      classroom: newValues.classroom,
      group: newValues.group
    };

    if (newValues.subjectRefId) {
      lessonDocument.subject = doc<Subject>(
        makeCollectionRef(FIRESTORE_COLLECTIONS.Subjects),
        newValues.subjectRefId
      );
    }

    if (newValues.teacherRefId) {
      lessonDocument.teacher = doc<Teacher>(
        makeCollectionRef(FIRESTORE_COLLECTIONS.Teachers),
        newValues.teacherRefId
      );
    }

    setValue(lessonDocument);
  };

  return {
    subjectsLoading,
    teachersLoading,
    selectedSubjectId,
    lesson,
    form,
    subjects,
    classrooms,
    classroomsLoading,
    availableTeachers,
    initialValues: useMemo<LessonFormValues>(
      () => ({
        id: lesson.id,
        classroom: lesson.classroom,
        group: lesson.group,
        subjectRefId: lesson.subject?.id,
        teacherRefId: lesson.teacher?.id
      }),
      [lesson]
    ),
    onChange
  };
};

export { useLessonPicker };
