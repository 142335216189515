import produce from "immer";
import get from "lodash/get";
import set from "lodash/set";
import { useEffect, useMemo } from "react";

import { TimetableDocument } from "~api/timetable/types";

import { useTimetable } from "./context";

const useTimetableField = <T = unknown>(
  fieldKey: string,
  defaultValue?: T
) => {
  const { timetable, setTimetable } = useTimetable();

  useEffect(() => {
    if (defaultValue === undefined) return;

    setTimetable((prev) => {
      const newValues = produce(prev, (draft: TimetableDocument) => {
        if (!draft) {
          return;
        }

        if (get(draft, fieldKey, undefined) !== undefined) return;

        set(draft, fieldKey, defaultValue);
      });

      return newValues;
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldKey, setTimetable]);

  return useMemo(
    () => ({
      value: get(timetable, fieldKey, defaultValue) as T,
      setValue: (value: T | null | undefined) => {
        const newValues = produce(
          timetable,
          (draft: TimetableDocument) => {
            if (!draft) {
              return;
            }

            set(draft, fieldKey, value);
          }
        );

        setTimetable(newValues);
      }
    }),
    [defaultValue, fieldKey, setTimetable, timetable]
  );
};

export { useTimetableField };
