import React from "react";
import Spin from "antd/lib/spin";
import { Button, Image, Typography } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { DeleteOutlined } from "@ant-design/icons";

import { useSaleOfferDetails } from "./hooks";

const SaleOfferDetailsPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const { saleOffer, items, isLoading, onDeleteClick } =
    useSaleOfferDetails();

  if (isLoading) return <Spin size="large" />;

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <Typography.Title>{saleOffer?.title}</Typography.Title>
        <Button
          danger
          icon={<DeleteOutlined />}
          onClick={() => onDeleteClick()}
        >
          <FormattedMessage
            tagName="span"
            defaultMessage="Usuń ogłoszenie"
          />
        </Button>
      </div>
      {items
        .filter((item) => item.content || item.items)
        .map((item) => (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: 8
            }}
            key={item.key}
          >
            <Typography.Title level={3} style={{ marginBottom: 4 }}>
              {item.title}:{" "}
            </Typography.Title>
            {item.content && (
              <Typography.Text>{item.content}</Typography.Text>
            )}
            {item.items && (
              <div
                style={{ display: "flex", flexDirection: "column" }}
              >
                {item.items
                  .filter((subItem) => subItem.content)
                  .map((subItem) => (
                    <React.Fragment key={subItem.key}>
                      <Typography.Title
                        level={5}
                        style={{ marginBottom: 4 }}
                      >
                        {subItem.title}:{" "}
                      </Typography.Title>
                      <Typography.Text style={{ marginBottom: 4 }}>
                        {subItem.content}
                      </Typography.Text>
                    </React.Fragment>
                  ))}
              </div>
            )}
          </div>
        ))}
      {saleOffer?.imgUrls.length && (
        <>
          <Typography.Title level={3} style={{ marginBottom: 4 }}>
            {formatMessage({ defaultMessage: "Zdjęcia" })}:
          </Typography.Title>
          <div style={{ display: "flex", gap: 8, flexWrap: "wrap" }}>
            {saleOffer.imgUrls.map((img) => (
              <Image width={200} key={img} src={img} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default SaleOfferDetailsPage;
