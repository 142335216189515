import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { memo, PropsWithChildren } from "react";
import { Layout as AntLayout, Menu } from "antd";
import LogoutOutlined from "@ant-design/icons/lib/icons/LogoutOutlined";

import { ROUTES } from "~router/consts";
import { useAuth } from "~providers/auth/context";
import { ReactComponent as Logo } from "~assets/images/logo/full-white.svg";
import { ReactComponent as SmallLogo } from "~assets/images/logo/small-white.svg";
import useLogoutModal from "~hooks/useLogoutModal";

import { useLayout, useNavigationItems } from "./hooks";

const { Header, Sider, Content } = AntLayout;

const Layout: React.FC<PropsWithChildren<unknown>> = ({
  children
}) => {
  const { user } = useAuth();
  const items = useNavigationItems();
  const { isCollapsed, pathname, openKeys, toggle, ToggleIcon } =
    useLayout();
  const logout = useLogoutModal();

  return (
    <AntLayout className="main-wrapper">
      <Sider
        breakpoint="md"
        onBreakpoint={(p) => toggle(p)}
        theme="dark"
        trigger={null}
        collapsible
        collapsed={isCollapsed}
      >
        <div className="sidebar-logo-wrapper">
          <Link to={ROUTES.Root} style={{ display: "flex" }}>
            {isCollapsed ? (
              <SmallLogo className="logo" />
            ) : (
              <Logo className="logo" />
            )}
          </Link>
        </div>
        <Menu
          theme="dark"
          mode="inline"
          defaultOpenKeys={openKeys}
          defaultSelectedKeys={[pathname]}
        >
          {items.map(
            ({ href, title, icon: Icon, children: nestedLinks }) =>
              nestedLinks ? (
                <Menu.SubMenu
                  icon={Icon ? <Icon /> : undefined}
                  key={`${href}_parent`}
                  title={title}
                >
                  {nestedLinks.map((childLink) => (
                    <Menu.Item
                      key={childLink.href}
                      icon={
                        childLink.icon ? (
                          <childLink.icon />
                        ) : undefined
                      }
                    >
                      <Link to={childLink.href}>
                        {childLink.title}
                      </Link>
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
              ) : (
                <Menu.Item
                  key={href}
                  icon={Icon ? <Icon /> : undefined}
                >
                  <Link to={href}>{title}</Link>
                </Menu.Item>
              )
          )}
          <Menu.Item
            icon={<LogoutOutlined />}
            key="logout-btn"
            onClick={() => logout()}
          >
            <FormattedMessage
              tagName="span"
              defaultMessage="Wyloguj"
            />
          </Menu.Item>
        </Menu>
      </Sider>
      <AntLayout className="site-layout">
        <Header className="header-layout">
          <ToggleIcon
            className="sidebar-toggle"
            onClick={() => toggle()}
          />
          <b>{user?.name || user?.email}</b>
        </Header>
        <Content className="content-layout">{children}</Content>
      </AntLayout>
    </AntLayout>
  );
};

export default memo(Layout);
