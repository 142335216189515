import Form from "antd/lib/form";
import useForm from "antd/lib/form/hooks/useForm";
import Col from "antd/lib/grid/col";
import Input from "antd/lib/input/Input";
import Select from "antd/lib/select";
import Title from "antd/lib/typography/Title";
import React, { useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import useCommonRules from "~hooks/useCommonRules";
import Button from "~components/Button";

import { SubjectFormProps, SubjectFormValues } from "./types";
import { useSubjectForm } from "./hooks";

const SubjectForm: React.FC<SubjectFormProps> = ({
  editMode,
  isLoading,
  onSubmit,
  initialValues
}) => {
  const [form] = useForm<SubjectFormValues>();
  const { isLoading: teachersLoading, teachers } = useSubjectForm();
  const { formatMessage } = useIntl();
  const { required } = useCommonRules();

  const { resetFields } = form;

  const handleSubmit = useCallback(
    async (data: SubjectFormValues) => {
      await onSubmit?.(data);

      resetFields();
    },
    [onSubmit, resetFields]
  );

  return (
    <Col lg={{ span: 12 }} md={{ span: 24 }}>
      <Title>
        {editMode ? (
          <FormattedMessage defaultMessage="Edytuj przedmiot" />
        ) : (
          <FormattedMessage defaultMessage="Dodaj nowy przedmiot" />
        )}
      </Title>
      <Form
        {...{ form, initialValues }}
        name="subject-form"
        onFinish={handleSubmit}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        autoComplete="off"
        preserve={false}
      >
        <Form.Item
          label={formatMessage({ defaultMessage: "Nazwa" })}
          name="name"
          rules={[required]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={formatMessage({ defaultMessage: "Skrót" })}
          name="shortcut"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={formatMessage({ defaultMessage: "Nauczyciele" })}
          name="teacherRefs"
          rules={[required]}
        >
          <Select
            mode="multiple"
            filterOption={(input, option) =>
              (option?.label ?? "")
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            loading={teachersLoading}
            disabled={teachersLoading}
            options={teachers}
          />
        </Form.Item>
        <Button htmlType="submit" type="primary" loading={isLoading}>
          {editMode ? (
            <FormattedMessage defaultMessage="Zapisz" />
          ) : (
            <FormattedMessage defaultMessage="Dodaj" />
          )}
        </Button>
      </Form>
    </Col>
  );
};

export default SubjectForm;
