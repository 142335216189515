import AuthProvider from "~providers/auth";
import IntlProvider from "~providers/intl";
import QueryProvider from "~providers/react-query";

import "./App.less";
import Router from "./router";

const App = () => (
  <IntlProvider>
    <QueryProvider>
      <AuthProvider>
        <Router />
      </AuthProvider>
    </QueryProvider>
  </IntlProvider>
);

export default App;
