import { useCallback, useMemo } from "react";
import Space from "antd/lib/space";
import { FormattedMessage, useIntl } from "react-intl";
import { ColumnType } from "antd/lib/table";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { generatePath, useNavigate } from "react-router-dom";
import { orderBy } from "firebase/firestore";

import Button from "~components/Button";
import { ClassProfile } from "~api/classProfiles/types";
import {
  useClassProfileDeletion,
  useGetClassProfilesQuery
} from "~api/classProfiles";
import { ROUTES } from "~router/consts";
import useConfirmModal from "~hooks/useConfirmModal";

const useClassProfiles = () => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { mutate: deleteClassProfile } = useClassProfileDeletion();
  const showConfirmModal = useConfirmModal();
  const { data = [], isLoading } = useGetClassProfilesQuery<
    WithId<ClassProfile>[]
  >({
    queryConstrains: [orderBy("name")],
    select: ({ docs }) =>
      docs.map((doc) => ({
        id: doc.id,
        name: doc.data().name || "",
        additionalSubjects: [],
        extendedSubjects: [],
        languageGroups: []
      }))
  });

  const onDeleteClick = useCallback(
    ({ id, name }: WithId<ClassProfile>) => {
      showConfirmModal({
        onOk: () => deleteClassProfile({ id }),
        title: formatMessage(
          {
            defaultMessage:
              "Czy na pewno chcesz usunąć profil klasy {name}?"
          },
          { name }
        )
      });
    },
    [showConfirmModal, formatMessage, deleteClassProfile]
  );

  const onEditClick = useCallback(
    (id: string) => {
      const path = generatePath(ROUTES.EditClassProfile, { id });

      navigate(path);
    },
    [navigate]
  );

  const columns: ColumnType<WithId<ClassProfile>>[] = useMemo(
    () => [
      {
        title: formatMessage({ defaultMessage: "Nazwa" }),
        dataIndex: "name",
        key: "name",
        render: (name) => <b>{name}</b>,
        sorter: (a, b) => a.name.localeCompare(b.name)
      },
      {
        title: formatMessage({ defaultMessage: "Operacje" }),
        key: "action",
        width: 200,
        render: (_, item) => (
          <Space size="middle">
            <Button
              icon={<EditOutlined />}
              onClick={() => onEditClick(item.id)}
            >
              <FormattedMessage
                tagName="span"
                defaultMessage="Edytuj"
              />
            </Button>
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => onDeleteClick(item)}
            >
              <FormattedMessage
                tagName="span"
                defaultMessage="Usuń"
              />
            </Button>
          </Space>
        )
      }
    ],
    [formatMessage, onDeleteClick, onEditClick]
  );

  return {
    loading: isLoading,
    columns,
    dataSource: data
  };
};

export { useClassProfiles };
