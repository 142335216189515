import { doc, setDoc } from "firebase/firestore";

import { ClassDocument } from "~api/classes/types";
import { Classroom } from "~api/classrooms/types";
import { SubjectDocument } from "~api/subjects/types";
import { Teacher } from "~api/teachers/types";
import { TimetableDocument } from "~api/timetable/types";
import { makeCollectionRef } from "~api/utils";
import { FIRESTORE_COLLECTIONS } from "~setup/consts/firestoreCollections";

import { LibrusTimetable, WithRef } from "../types";

import prepareClassTimetable from "./prepareClassTimetable";

const importTimetables = async (
  {
    timetable: { lessons: librusLessons, cards: librusCards }
  }: LibrusTimetable,
  classes: WithRef<WithId<ClassDocument>>[],
  subjects: WithRef<SubjectDocument>[],
  teachers: WithRef<Teacher>[],
  classrooms: Classroom[],
  groups: Record<string, string>,
  periodsCount: number
): Promise<boolean> => {
  try {
    const ref = makeCollectionRef<TimetableDocument>(
      FIRESTORE_COLLECTIONS.Timetables
    );

    await Promise.all(
      classes
        .filter((existingClass) => !!existingClass.librusId)
        .map((existingClass) => {
          const timetableData = prepareClassTimetable(
            existingClass.librusId!,
            librusLessons.lesson,
            librusCards.card,
            classes,
            subjects,
            teachers,
            classrooms,
            groups,
            periodsCount
          );

          return setDoc(doc(ref, existingClass.id), timetableData);
        })
    );

    return true;
  } catch {
    return false;
  }
};

export default importTimetables;
