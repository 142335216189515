import { useMemo } from "react";
import { useIntl } from "react-intl";

const useGrades = () => {
  const { formatMessage } = useIntl();

  return useMemo(
    () => [
      {
        label: formatMessage({ defaultMessage: "Ocena celująca" }),
        key: "six",
        default: 18
      },
      {
        label: formatMessage({
          defaultMessage: "Ocena bardzo dobra"
        }),
        key: "five",
        default: 17
      },
      {
        label: formatMessage({ defaultMessage: "Ocena dobra" }),
        key: "four",
        default: 14
      },
      {
        label: formatMessage({ defaultMessage: "Ocena dostateczna" }),
        key: "three",
        default: 8
      },
      {
        label: formatMessage({
          defaultMessage: "Ocena dopuszczająca"
        }),
        key: "two",
        default: 2
      }
    ],
    [formatMessage]
  );
};

export { useGrades };
