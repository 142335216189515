import groupTeachersBySubject from "./helpers/groupTeachersBySubject";
import importClasses from "./helpers/importClases";
import importClassrooms from "./helpers/importClassrooms";
import importPeriods from "./helpers/importPeriods";
import importSubjects from "./helpers/importSubjects";
import importTeachers from "./helpers/importTeachers";
import importTimetables from "./helpers/importTimetables";
import { LibrusTimetable } from "./types";

const importData = async (data: LibrusTimetable) => {
  const {
    classrooms: librusClassrooms,
    teachers: librusTeachers,
    lessons: librusLessons,
    subjects: librusSubjects,
    classes: librusClasses,
    periods: librusPeriods,
    groups: librusGroups
  } = data.timetable;

  const [classrooms, teachers] = await Promise.all([
    importClassrooms(librusClassrooms.classroom),
    importTeachers(librusTeachers.teacher)
  ]);

  const teachersBySubject = groupTeachersBySubject(
    librusLessons.lesson,
    teachers
  );

  const subjects = await importSubjects(
    librusSubjects.subject,
    teachersBySubject
  );

  const classes = await importClasses(librusClasses.class, teachers);

  const groups = librusGroups.group.reduce<Record<string, string>>(
    (prev, current) => ({
      ...prev,
      [current.id]: current.name
    }),
    {}
  );

  const lessonTimes = await importPeriods(librusPeriods.period);

  await importTimetables(
    data,
    classes,
    subjects,
    teachers,
    classrooms,
    groups,
    lessonTimes.length
  );
};

export default importData;
