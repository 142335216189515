import React, { PropsWithChildren } from "react";
import { QueryClientProvider } from "react-query";

import { queryClient as client } from "./consts";

const QueryProvider: React.FC<PropsWithChildren<unknown>> = ({
  children
}) => (
  <QueryClientProvider {...{ client }}>
    {children}
  </QueryClientProvider>
);

export default QueryProvider;
