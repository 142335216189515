import { Navigate, Outlet } from "react-router-dom";

import { useAuth } from "~providers/auth/context";
import AuthLoader from "~components/AuthLoader";

import { ROUTES } from "./consts";

const PublicRoutes = () => {
  const { isAuthLoading, isLoggedIn } = useAuth();

  if (isAuthLoading) return <AuthLoader />;

  return isLoggedIn ? (
    <Navigate to={ROUTES.Root} replace />
  ) : (
    <Outlet />
  );
};

export default PublicRoutes;
