import { useMemo } from "react";
import { useIntl } from "react-intl";

export enum WorkingDay {
  Monday = "mon",
  Tuesday = "tue",
  Wednesday = "wed",
  Thursday = "thu",
  Friday = "fri"
}

const useWorkingDays = () => {
  const { formatMessage } = useIntl();

  return useMemo(
    () => [
      {
        key: WorkingDay.Monday,
        label: formatMessage({ defaultMessage: "Poniedziałek" })
      },
      {
        key: WorkingDay.Tuesday,
        label: formatMessage({ defaultMessage: "Wtorek" })
      },
      {
        key: WorkingDay.Wednesday,
        label: formatMessage({ defaultMessage: "Środa" })
      },
      {
        key: WorkingDay.Thursday,
        label: formatMessage({ defaultMessage: "Czwartek" })
      },
      {
        key: WorkingDay.Friday,
        label: formatMessage({ defaultMessage: "Piątek" })
      }
    ],
    [formatMessage]
  );
};

export default useWorkingDays;
