import { useIntl } from "react-intl";
import { Table, Typography } from "antd";
import { useNavigate } from "react-router-dom";

import Button from "~components/Button";
import { ROUTES } from "~router/consts";
import useTableLocale from "~hooks/useTableLocale";

import { useTeachers } from "./hooks";

const TeachersListPage = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { columns, dataSource, loading } = useTeachers();
  const locale = useTableLocale();

  return (
    <>
      <div className="list-header">
        <Typography.Title>
          {formatMessage({ defaultMessage: "Nauczyciele" })}
        </Typography.Title>
        <Button onClick={() => navigate(ROUTES.NewTeacher)}>
          {formatMessage({ defaultMessage: "Dodaj nauczyciela" })}
        </Button>
      </div>
      <Table
        rowKey="id"
        {...{ columns, loading, dataSource, locale }}
      />
    </>
  );
};

export default TeachersListPage;
