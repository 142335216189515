import { FIRESTORE_COLLECTIONS } from "~setup/consts/firestoreCollections";

import {
  makeFirestoreDocumentHookWithKey,
  makeFirestoreDocumentMutationHook
} from "../utils";

import { CONSTANTS_KEYS } from "./consts";
import {
  EventCategoriesDocument,
  MarketplaceDocument,
  RecruitmentDocument
} from "./types";

const useGetEventCategoriesQuery =
  makeFirestoreDocumentHookWithKey<EventCategoriesDocument>(
    FIRESTORE_COLLECTIONS.Constants,
    CONSTANTS_KEYS.EventCategories
  );

const useGetRecruitmentQuery =
  makeFirestoreDocumentHookWithKey<RecruitmentDocument>(
    FIRESTORE_COLLECTIONS.Constants,
    CONSTANTS_KEYS.Recruitment
  );

const useUpdateRecruitmentMutation =
  makeFirestoreDocumentMutationHook<Partial<RecruitmentDocument>>(
    FIRESTORE_COLLECTIONS.Constants
  );

const useGetMarketplaceQuery =
  makeFirestoreDocumentHookWithKey<MarketplaceDocument>(
    FIRESTORE_COLLECTIONS.Constants,
    CONSTANTS_KEYS.Marketplace
  );

const useUpdateMarketplaceMutation =
  makeFirestoreDocumentMutationHook<MarketplaceDocument>(
    FIRESTORE_COLLECTIONS.Constants
  );

export {
  useGetEventCategoriesQuery,
  useUpdateRecruitmentMutation,
  useGetMarketplaceQuery,
  useUpdateMarketplaceMutation,
  useGetRecruitmentQuery
};
