import Form from "antd/lib/form";
import InputNumber from "antd/lib/input-number";
import Title from "antd/lib/typography/Title";
import { FormattedMessage } from "react-intl";

import useCommonRules from "~hooks/useCommonRules";

import { useMultipliers } from "./hooks";

const Multipliers: React.FC = () => {
  const { required } = useCommonRules();
  const multipliers = useMultipliers();

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Title level={4}>
        <FormattedMessage defaultMessage="Mnożniki" />
      </Title>
      {multipliers.map((multiplier) => (
        <Form.Item
          name={["multipliers", multiplier.key]}
          key={multiplier.key}
          label={multiplier.label}
          labelCol={{ sm: 12 }}
          rules={[required]}
        >
          <InputNumber
            defaultValue={multiplier.default}
            min={0}
            max={1}
            step={0.01}
            precision={2}
          />
        </Form.Item>
      ))}
    </div>
  );
};

export default Multipliers;
