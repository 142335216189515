import { FormattedMessage } from "react-intl";
import Title from "antd/lib/typography/Title";
import Spin from "antd/lib/spin";
import Col from "antd/lib/col";

import LessonTimesForm from "./partials/form";
import { useLessonTimes } from "./hooks";

const LessonTimesPage = () => {
  const { initialValues, onSubmit, isLoading, isUpdating } =
    useLessonTimes();

  return (
    <Col lg={{ span: 12 }} md={{ span: 24 }}>
      <Title>
        <FormattedMessage defaultMessage="Godziny lekcyjne" />
      </Title>
      {isLoading ? (
        <Spin size="large" />
      ) : (
        <LessonTimesForm
          {...{ isLoading: isUpdating, initialValues, onSubmit }}
        />
      )}
    </Col>
  );
};

export default LessonTimesPage;
