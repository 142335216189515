import { useGetSubjectsQuery } from "~api/subjects";

const useClassProfileForm = () => {
  const { data: subjects = [], isLoading: subjectsLoading } =
    useGetSubjectsQuery({
      select: (snapshot) =>
        snapshot.docs.map((doc) => ({
          value: doc.ref.path,
          label: doc.data().name
        }))
    });

  return { subjectsLoading, subjects };
};

export { useClassProfileForm };
