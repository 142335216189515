import { useCallback } from "react";
import { useIntl } from "react-intl";
import notification from "antd/lib/notification";
import { doc } from "firebase/firestore";

import { queryClient } from "~providers/react-query/consts";
import { FIRESTORE_COLLECTIONS } from "~setup/consts/firestoreCollections";
import { useCreateClassMutation } from "~api/classes";
import { Firestore } from "~setup/lib/firebase";

import { ClassFormValues } from "../partials/form/types";

const useNewClass = () => {
  const { formatMessage } = useIntl();
  const { mutateAsync, isLoading } = useCreateClassMutation();

  const onSubmit = useCallback(
    async (data: ClassFormValues) => {
      await mutateAsync({
        name: data.name,
        supervisor: doc(Firestore, data.supervisorRef)
      });

      await queryClient.invalidateQueries(
        FIRESTORE_COLLECTIONS.Classes
      );

      notification.success({
        message: formatMessage({
          defaultMessage: "Klasa została dodana"
        })
      });
    },
    [formatMessage, mutateAsync]
  );

  return { onSubmit, isLoading };
};

export { useNewClass };
