import { useCallback, useMemo, useState } from "react";
import Space from "antd/lib/space";
import { FormattedMessage, useIntl } from "react-intl";
import { ColumnType } from "antd/lib/table";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";
import { orderBy } from "firebase/firestore";

import { Administrator } from "~api/administrators/types";
import {
  useGetAdministrators,
  useAdministratorDeletion
} from "~api/administrators";
import useConfirmModal from "~hooks/useConfirmModal";
import Button from "~components/Button";

import { UseAdministratorsReturnType } from "./types";

const useAdministrators = (): UseAdministratorsReturnType => {
  const { formatMessage } = useIntl();
  const [isFormOpen, setFormOpen] = useState(false);

  const { data, isLoading, refetch } = useGetAdministrators({
    queryConstrains: [orderBy("email")]
  });
  const { mutate: deleteAdministrator } = useAdministratorDeletion({
    onSuccess: () => refetch()
  });

  const showConfirmModal = useConfirmModal();

  const onDeleteClick = useCallback(
    ({ id, email }: WithId<Administrator>) => {
      showConfirmModal({
        onOk: () => deleteAdministrator({ id }),
        title: formatMessage(
          {
            defaultMessage:
              "Czy na pewno chcesz zabrać uprawnienia administratora dla {email}"
          },
          { email }
        )
      });
    },
    [formatMessage, deleteAdministrator, showConfirmModal]
  );

  const columns: ColumnType<WithId<Administrator>>[] = useMemo(
    () => [
      {
        title: formatMessage({ defaultMessage: "Adres e-mail" }),
        dataIndex: "email",
        key: "email",
        sorter: (a, b) => a.email.localeCompare(b.email)
      },
      {
        title: formatMessage({ defaultMessage: "Operacje" }),
        key: "action",
        width: 150,
        render: (_, item) => (
          <Space size="middle">
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => onDeleteClick(item)}
            >
              <FormattedMessage
                tagName="span"
                defaultMessage="Usuń"
              />
            </Button>
          </Space>
        )
      }
    ],
    [formatMessage, onDeleteClick]
  );

  const onAddAdministrator = useCallback(() => {
    refetch();
    setFormOpen(false);
  }, [refetch]);

  return {
    loading: isLoading,
    columns,
    dataSource:
      data?.docs.map((admin) => ({
        id: admin.id,
        email: admin.data().email
      })) || [],
    isFormOpen,
    openForm: () => setFormOpen(true),
    closeForm: () => setFormOpen(false),
    onAddAdministrator
  };
};

export { useAdministrators };
