import { useCallback, useMemo } from "react";
import Space from "antd/lib/space";
import { FormattedMessage, useIntl } from "react-intl";
import { ColumnType } from "antd/lib/table";
import { DeleteOutlined, ZoomInOutlined } from "@ant-design/icons";
import { generatePath, useNavigate } from "react-router-dom";

import Button from "~components/Button";
import useConfirmModal from "~hooks/useConfirmModal";
import { ROUTES } from "~router/consts";
import {
  useGetSaleOffersQuery,
  useSaleOfferDeletion
} from "~api/sale-offers";
import { SaleOffer } from "~api/sale-offers/types";

const useSaleOffers = () => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { mutate } = useSaleOfferDeletion();
  const showConfirmModal = useConfirmModal();
  const { data: saleOffers = [], isLoading } = useGetSaleOffersQuery({
    select: (snapshot) =>
      snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
  });

  const onPreviewClick = useCallback(
    (id: string) => {
      const path = generatePath(ROUTES.SaleOfferDetails, { id });

      navigate(path);
    },
    [navigate]
  );

  const onDeleteClick = useCallback(
    ({ id, title }: WithId<SaleOffer>) => {
      showConfirmModal({
        onOk: () => mutate({ id }),
        title: formatMessage(
          {
            defaultMessage:
              "Czy na pewno chcesz usunąć ogłoszenie {title}?"
          },
          { title }
        )
      });
    },
    [formatMessage, mutate, showConfirmModal]
  );

  const columns: ColumnType<WithId<SaleOffer>>[] = useMemo(
    () => [
      {
        title: formatMessage({
          defaultMessage: "Data i godzina dodania"
        }),
        dataIndex: "createdAt",
        key: "createdAt",
        width: 200,
        sorter: (a, b) => a.createdAt.seconds - b.createdAt.seconds,
        render: (createdAt: SaleOffer["createdAt"]) =>
          createdAt.toDate().toLocaleString().slice(0, -3)
      },
      {
        title: formatMessage({ defaultMessage: "Nazwa ogłoszenia" }),
        dataIndex: "title",
        key: "title",
        sorter: (a, b) => a.title.localeCompare(b.title)
      },
      {
        title: formatMessage({ defaultMessage: "Dodane przez" }),
        dataIndex: ["seller", "name"],
        key: "seller",
        width: 220,
        sorter: (a, b) =>
          (a.seller?.name || "").localeCompare(b.seller?.name || "")
      },
      {
        title: formatMessage({ defaultMessage: "Operacje" }),
        key: "actions",
        width: 200,
        render: (_, item) => (
          <Space size="middle">
            <Button
              icon={<ZoomInOutlined />}
              onClick={() => onPreviewClick(item.id)}
            >
              <FormattedMessage
                tagName="span"
                defaultMessage="Zobacz szczegóły"
              />
            </Button>
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => onDeleteClick(item)}
            >
              <FormattedMessage
                tagName="span"
                defaultMessage="Usuń"
              />
            </Button>
          </Space>
        )
      }
    ],
    [formatMessage, onDeleteClick, onPreviewClick]
  );

  return {
    loading: isLoading,
    columns,
    dataSource: saleOffers
  };
};

export { useSaleOffers };
