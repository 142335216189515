import { addDoc, getDocs, query } from "firebase/firestore";

import { SubjectDocument } from "~api/subjects/types";
import { Teacher } from "~api/teachers/types";
import { makeCollectionRef } from "~api/utils";
import { FIRESTORE_COLLECTIONS } from "~setup/consts/firestoreCollections";

import { LibrusSubject, WithRef } from "../types";

const importSubjects = async (
  librusSubjects: LibrusSubject[],
  teachersBySubject: Record<string, WithRef<Teacher>[]>
): Promise<WithRef<SubjectDocument>[]> => {
  const ref = makeCollectionRef<SubjectDocument>(
    FIRESTORE_COLLECTIONS.Subjects
  );

  const { docs } = await getDocs(query(ref));

  const existingSubjects =
    docs.map((doc) => ({
      ...doc.data(),
      ref: doc.ref
    })) || [];

  const newSubjects = await librusSubjects.reduce(
    async (prev, current) => {
      const awaitedPrev = await prev;

      if (
        existingSubjects.findIndex(
          (subject) => subject.librusId === current.id
        ) >= 0
      ) {
        return awaitedPrev;
      }

      const newSubjectData: SubjectDocument = {
        name: current.name,
        shortcut: current.short,
        librusId: current.id,
        teachers: (teachersBySubject[current.id] || []).map(
          (teacher) => teacher.ref
        )
      };

      const newSubjectReference = await addDoc(ref, newSubjectData);

      return [
        ...awaitedPrev,
        {
          ...newSubjectData,
          ref: newSubjectReference
        }
      ];
    },
    Promise.resolve<WithRef<SubjectDocument>[]>([])
  );

  return [...existingSubjects, ...newSubjects];
};

export default importSubjects;
