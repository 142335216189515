import moment from "moment";

export const DEFAULT_DATE_FORMAT = "DD MMMM YYYY";
export const DEFAULT_TIME_FORMAT = "HH:mm";

const formatTime = (
  date?: Date | string | moment.Moment,
  formatStr: string = DEFAULT_TIME_FORMAT
): string => {
  if (!date) {
    return "";
  }

  return moment(date).format(formatStr);
};

export { formatTime };
