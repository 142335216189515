import Form from "antd/lib/form";
import useForm from "antd/lib/form/hooks/useForm";
import Col from "antd/lib/grid/col";
import Input from "antd/lib/input/Input";
import Title from "antd/lib/typography/Title";
import React, { useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import useCommonRules from "~hooks/useCommonRules";
import Button from "~components/Button";
import SelectFieldArray from "~components/SelectFieldArray";

import {
  ClassProfileFormProps,
  ClassProfileFormValues
} from "./types";
import { useClassProfileForm } from "./hooks";

const ClassProfileForm: React.FC<ClassProfileFormProps> = ({
  editMode,
  isLoading,
  onSubmit,
  initialValues
}) => {
  const [form] = useForm<ClassProfileFormValues>();
  const { subjectsLoading, subjects } = useClassProfileForm();
  const { formatMessage } = useIntl();
  const { required } = useCommonRules();
  const { resetFields } = form;

  const handleSubmit = useCallback(
    async (data: ClassProfileFormValues) => {
      await onSubmit?.(data);

      if (!editMode) {
        resetFields();
      }
    },
    [editMode, onSubmit, resetFields]
  );

  return (
    <Col lg={{ span: 12 }} md={{ span: 24 }}>
      <Title>
        {editMode ? (
          <FormattedMessage defaultMessage="Edytuj profil klasy" />
        ) : (
          <FormattedMessage defaultMessage="Dodaj nowy profil klasy" />
        )}
      </Title>
      <Form
        {...{ form, initialValues }}
        name="class-form"
        onFinish={handleSubmit}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        autoComplete="off"
        preserve={false}
      >
        <Form.Item
          label={formatMessage({ defaultMessage: "Nazwa oddziału" })}
          name="name"
          rules={[required]}
        >
          <Input />
        </Form.Item>
        <SelectFieldArray
          label={
            <FormattedMessage defaultMessage="Przedmioty realizowane w zakresie rozszerzonym" />
          }
          options={subjects}
          loading={subjectsLoading}
          listName="extendedSubjectsRefs"
          min={1}
          arrayRules={[required]}
          rules={[required]}
          selectPlaceholder={
            <FormattedMessage defaultMessage="Wybierz przedmiot" />
          }
          addBtnLabel={
            <FormattedMessage defaultMessage="Dodaj przedmiot" />
          }
        />
        <SelectFieldArray
          label={formatMessage({
            defaultMessage: "Przedmioty uzupełniające"
          })}
          listName="additionalSubjectsRefs"
          options={subjects}
          loading={subjectsLoading}
          rules={[required]}
          selectPlaceholder={
            <FormattedMessage defaultMessage="Wybierz przedmiot" />
          }
          addBtnLabel={
            <FormattedMessage defaultMessage="Dodaj przedmiot" />
          }
        />
        <SelectFieldArray
          label={formatMessage({
            defaultMessage: "Języki obce"
          })}
          mode="multiple"
          listName="languageGroups"
          name="languageRefs"
          min={1}
          arrayRules={[required]}
          options={subjects}
          loading={subjectsLoading}
          rules={[required]}
          selectPlaceholder={
            <FormattedMessage defaultMessage="Wybierz język" />
          }
          addBtnLabel={
            <FormattedMessage defaultMessage="Dodaj grupę języków" />
          }
        />
        <Button htmlType="submit" type="primary" loading={isLoading}>
          {editMode ? (
            <FormattedMessage defaultMessage="Zapisz" />
          ) : (
            <FormattedMessage defaultMessage="Dodaj" />
          )}
        </Button>
      </Form>
    </Col>
  );
};

export default ClassProfileForm;
