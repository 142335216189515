import { useCallback, useMemo, useState } from "react";
import Space from "antd/lib/space";
import { FormattedMessage, useIntl } from "react-intl";
import { ColumnType } from "antd/lib/table";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { generatePath, useNavigate } from "react-router-dom";
import { orderBy, where } from "firebase/firestore";

import Button from "~components/Button";
import useConfirmModal from "~hooks/useConfirmModal";
import { ROUTES } from "~router/consts";
import { useEventDeletion, useGetEventsQuery } from "~api/events";
import { Event } from "~api/events/types";
import { useGetEventCategoriesQuery } from "~api/constants";

import { EventType } from "./consts";

const useEvents = (type = EventType.ALL) => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { mutate } = useEventDeletion();
  const showConfirmModal = useConfirmModal();
  const [searchQuery, setSearchQuery] = useState("");
  const { data: categories = [] } = useGetEventCategoriesQuery({
    select: (snapshot) => snapshot.data()?.categories
  });

  const {
    data: dataWithoutEndDate = [],
    isLoading: isLoadingWithoutEndDate
  } = useGetEventsQuery<WithId<Event>[]>({
    queryKey: `events_${type}`,
    queryConstrains: [
      orderBy(
        "date.start",
        type === EventType.ARCHIVE ? "desc" : "asc"
      ),
      where("date.end", "==", null),
      where(
        "date.start",
        type === EventType.ARCHIVE ? "<" : ">=",
        new Date()
      )
    ],
    select: (snapshot) =>
      snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }))
  });

  const {
    data: dataWithEndDate = [],
    isLoading: isLoadingWithEndDate
  } = useGetEventsQuery<WithId<Event>[]>({
    queryKey: `events_${type}_2`,
    queryConstrains: [
      orderBy(
        "date.end",
        type === EventType.ARCHIVE ? "desc" : "asc"
      ),
      where("date.end", "!=", null),
      where(
        "date.end",
        type === EventType.ARCHIVE ? "<" : ">=",
        new Date()
      )
    ],
    select: (snapshot) =>
      snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }))
  });

  const onEditClick = useCallback(
    (id: string) => {
      const path = generatePath(ROUTES.EditEvent, { id });

      navigate(path);
    },
    [navigate]
  );

  const onDeleteClick = useCallback(
    ({ id, title }: WithId<Event>) => {
      showConfirmModal({
        onOk: () => mutate({ id }),
        title: formatMessage(
          {
            defaultMessage:
              "Czy na pewno chcesz usunąć wydarzenie {title}?"
          },
          { title }
        )
      });
    },
    [formatMessage, mutate, showConfirmModal]
  );

  const columns: ColumnType<WithId<Event>>[] = useMemo(
    () => [
      {
        title: formatMessage({ defaultMessage: "Data i godzina" }),
        dataIndex: "date",
        key: "date",
        defaultSortOrder: EventType.ARCHIVE ? "descend" : "ascend",
        width: 200,
        sorter: (a, b) => a.date.start.seconds - b.date.start.seconds,
        render: (date: Event["date"]) => {
          if (date.end) {
            return (
              <>
                <p>
                  Od:{" "}
                  {date.start.toDate().toLocaleString().slice(0, -3)}
                </p>
                {date.end && (
                  <p>
                    Do:{" "}
                    {date.end.toDate().toLocaleString().slice(0, -3)}
                  </p>
                )}
              </>
            );
          }

          return date.start.toDate().toLocaleString().slice(0, -3);
        }
      },
      {
        title: formatMessage({ defaultMessage: "Nazwa wydarzenia" }),
        dataIndex: "title",
        key: "title",
        sorter: (a, b) => a.title.localeCompare(b.title)
      },
      {
        title: formatMessage({ defaultMessage: "Kategoria" }),
        dataIndex: "category",
        key: "category",
        width: 220,
        render: (category: Event["category"], record) => (
          <div className="categories-selection">
            {category.color && (
              <span
                className="dot"
                style={{ backgroundColor: category.color }}
              />
            )}
            {category.name}{" "}
            {record.subcategory
              ? ` (${record.subcategory.name})`
              : ""}
          </div>
        ),
        filterMode: "tree",
        filters: categories.map((category) => ({
          text: (
            <div className="categories-selection">
              {category.color && (
                <span
                  className="dot"
                  style={{ backgroundColor: category.color }}
                />
              )}
              {category.name}
            </div>
          ),

          children: category.subcategories.map((subcategory) => ({
            text: subcategory.name,
            value: subcategory.id
          })),
          value: category.id
        })),
        onFilter: (value, record) => record.category.id === value
      },
      {
        title: formatMessage({ defaultMessage: "Operacje" }),
        key: "actions",
        width: 200,
        render: (_, item) => (
          <Space size="middle">
            <Button
              icon={<EditOutlined />}
              onClick={() => onEditClick(item.id)}
            >
              <FormattedMessage
                tagName="span"
                defaultMessage="Edytuj"
              />
            </Button>
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => onDeleteClick(item)}
            >
              <FormattedMessage
                tagName="span"
                defaultMessage="Usuń"
              />
            </Button>
          </Space>
        )
      }
    ],
    [categories, formatMessage, onDeleteClick, onEditClick]
  );

  const dataSource = useMemo(
    () =>
      [...dataWithoutEndDate, ...dataWithEndDate]
        .sort((a, b) => {
          if (a < b) return 1;
          if (a < b) return -1;

          return 0;
        })
        .filter((record) =>
          record.title
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        ),
    [dataWithoutEndDate, dataWithEndDate, searchQuery]
  );

  const onSearchQueryChanged = useCallback(
    ({
      currentTarget: { value }
    }: React.ChangeEvent<HTMLInputElement>) => {
      setSearchQuery(value);
    },
    []
  );

  return {
    loading: isLoadingWithoutEndDate || isLoadingWithEndDate,
    columns,
    dataSource,
    searchQuery,
    onSearchQueryChanged
  };
};

export { useEvents };
