import { Navigate, Outlet, useLocation } from "react-router-dom";

import Layout from "~components/layout";
import { useAuth } from "~providers/auth/context";
import AuthLoader from "~components/AuthLoader";

import { ROUTES } from "./consts";

const ProtectedRoutes = () => {
  const location = useLocation();
  const { isAuthLoading, isLoggedIn } = useAuth();

  if (isAuthLoading) return <AuthLoader />;

  return isLoggedIn ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : (
    <Navigate to={ROUTES.Login} replace state={{ from: location }} />
  );
};

export default ProtectedRoutes;
