import { doc, getDoc, updateDoc } from "firebase/firestore";

import { LessonTime, LessonTimes } from "~api/lesson-times/types";
import { makeCollectionRef } from "~api/utils";
import { LESSON_TIMES_ID } from "~setup/consts/environment";
import { FIRESTORE_COLLECTIONS } from "~setup/consts/firestoreCollections";

import { LibrusPeriod } from "../types";

const importPeriods = async (
  librusPeriods: LibrusPeriod[]
): Promise<LessonTime[]> => {
  const ref = makeCollectionRef<LessonTimes>(
    FIRESTORE_COLLECTIONS.LessonTimes
  );

  const docRef = doc(ref, LESSON_TIMES_ID);

  const document = await getDoc(docRef);
  const existingLessonTimes = document.data()?.lessonTimes || [];

  const updatedLessonTimes = [
    ...existingLessonTimes,
    ...librusPeriods
      .filter(
        (period) =>
          existingLessonTimes.findIndex(
            (existing) => existing.librusId === period.period
          ) < 0
      )
      .map((lessonTime) => ({
        from: lessonTime.starttime,
        to: lessonTime.endtime,
        librusId: lessonTime.period
      }))
  ];

  await updateDoc(docRef, { lessonTimes: updatedLessonTimes });

  return updatedLessonTimes;
};

export default importPeriods;
