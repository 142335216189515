import { useMemo } from "react";

import { useGetTeachersQuery } from "~api/teachers";

const useClassForm = () => {
  const { isLoading, data } = useGetTeachersQuery();

  return useMemo(
    () => ({
      isLoading,
      teachers: data?.docs.map((doc) => {
        const { firstName, lastName } = doc.data();

        return {
          label: `${firstName} ${lastName}`,
          value: doc.ref.path
        };
      })
    }),
    [isLoading, data]
  );
};

export { useClassForm };
