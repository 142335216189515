import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import notification from "antd/lib/notification";

import {
  useGetTeacherQuery,
  useUpdateTeacherMutation
} from "~api/teachers";
import { queryClient } from "~providers/react-query/consts";
import { FIRESTORE_COLLECTIONS } from "~setup/consts/firestoreCollections";

import { TeacherFormValues } from "../partials/form/types";

const useEditTeacher = () => {
  const { id } = useParams();
  const { formatMessage } = useIntl();
  const { isLoading: isTeacherLoading, data: teacherData = {} } =
    useGetTeacherQuery(id, {
      enabled: !!id,
      select: (snapshot) => ({
        firstName: snapshot?.data()?.firstName ?? "",
        lastName: snapshot?.data()?.lastName ?? "",
        shortcut: snapshot?.data()?.shortcut ?? ""
      })
    });

  const { mutateAsync, isLoading } = useUpdateTeacherMutation();

  const onUpdate = useCallback(
    async (formData: TeacherFormValues) => {
      if (!id) return;

      await mutateAsync({
        id,
        firstName: formData.firstName,
        lastName: formData.lastName,
        shortcut: formData.shortcut
      });

      await queryClient.invalidateQueries(
        FIRESTORE_COLLECTIONS.Teachers
      );

      notification.success({
        message: formatMessage({
          defaultMessage: "Dane zostały zaktualizowane"
        })
      });
    },
    [formatMessage, id, mutateAsync]
  );

  return { isTeacherLoading, teacherData, onUpdate, isLoading };
};

export { useEditTeacher };
