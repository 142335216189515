import { Teacher } from "~api/teachers/types";

import { LibrusLesson, WithRef } from "../types";

const groupTeachersBySubject = (
  librusLessons: LibrusLesson[],
  teachers: WithRef<Teacher>[]
): Record<string, WithRef<Teacher>[]> =>
  librusLessons.reduce((prev, current) => {
    const prevSubjectTeachers = prev[current.subjectid] || [];
    const subjectTeachers = current.teacherids
      .split(",")
      .filter(
        (teacherId) =>
          prevSubjectTeachers.findIndex(
            (teacher) => teacher.librusId === teacherId
          ) < 0
      )
      .map((teacherId) =>
        teachers.find((teacher) => teacher.librusId === teacherId)
      )
      .filter((teacher): teacher is WithRef<Teacher> => !!teacher);

    return {
      ...prev,
      [current.subjectid]: [
        ...(prev[current.subjectid] || []),
        ...subjectTeachers
      ]
    };
  }, {} as Record<string, WithRef<Teacher>[]>);

export default groupTeachersBySubject;
