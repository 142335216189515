import { Spin } from "antd";
import Button from "antd/lib/button";
import Form from "antd/lib/form";
import Input from "antd/lib/input/Input";
import { FormattedMessage, useIntl } from "react-intl";

import useCommonRules from "~hooks/useCommonRules";

import {
  DEFAULT_ADDITIONAL_SUBJECTS,
  DEFAULT_REQUIRED_SUBJECTS
} from "./consts";
import useScoredSubjects from "./hooks";
import SubjectsForm from "./partials/SubjectsForm";

const ScoredSubjectsForm: React.FC = () => {
  const { formatMessage } = useIntl();
  const { required, positiveInteger } = useCommonRules();
  const { form, onSubmit, recruitmentData, isSubmitting, isLoading } =
    useScoredSubjects();

  if (isLoading) return <Spin size="large" />;

  return (
    <Form
      {...{ form }}
      initialValues={recruitmentData}
      onFinish={onSubmit}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      autoComplete="off"
      preserve={false}
    >
      <div style={{ display: "flex", gap: 24, flexWrap: "wrap" }}>
        <SubjectsForm
          name={["scoredSubjects", "required"]}
          label={formatMessage({
            defaultMessage: "Przedmioty obowiązkowe"
          })}
          options={DEFAULT_REQUIRED_SUBJECTS}
        />
        <SubjectsForm
          name={["scoredSubjects", "additional"]}
          label={formatMessage({
            defaultMessage: "Przedmioty obieralne"
          })}
          options={DEFAULT_ADDITIONAL_SUBJECTS}
        />
      </div>
      <Form.Item
        label={formatMessage({
          defaultMessage:
            "Liczba obieralnych przedmiotów punktowanych"
        })}
        name={["scoredSubjects", "additionalSubjectsLimit"]}
        rules={[required, positiveInteger]}
      >
        <Input style={{ width: "auto" }} type="number" />
      </Form.Item>
      <Button
        loading={isSubmitting}
        style={{ marginTop: 12 }}
        htmlType="submit"
        type="primary"
      >
        <FormattedMessage defaultMessage="Zapisz" />
      </Button>
    </Form>
  );
};

export default ScoredSubjectsForm;
