import { FIRESTORE_COLLECTIONS } from "~setup/consts/firestoreCollections";

import {
  makeFirestoreCollectionMutationHook,
  makeFirestoreDocumentDeletionHook,
  makeFirestoreDocumentHook,
  makeFirestoreDocumentMutationHook,
  makeFirestoreQueryHook
} from "../utils";

import { ClassProfileDocument } from "./types";

const useGetClassProfilesQuery =
  makeFirestoreQueryHook<ClassProfileDocument>(
    FIRESTORE_COLLECTIONS.ClassProfiles
  );

const useGetClassProfileQuery =
  makeFirestoreDocumentHook<ClassProfileDocument>(
    FIRESTORE_COLLECTIONS.ClassProfiles
  );

const useCreateClassProfileMutation =
  makeFirestoreCollectionMutationHook<ClassProfileDocument>(
    FIRESTORE_COLLECTIONS.ClassProfiles
  );

const useUpdateClassProfileMutation =
  makeFirestoreDocumentMutationHook<ClassProfileDocument>(
    FIRESTORE_COLLECTIONS.ClassProfiles
  );

const useClassProfileDeletion = makeFirestoreDocumentDeletionHook(
  FIRESTORE_COLLECTIONS.ClassProfiles
);

export {
  useGetClassProfilesQuery,
  useCreateClassProfileMutation,
  useUpdateClassProfileMutation,
  useClassProfileDeletion,
  useGetClassProfileQuery
};
