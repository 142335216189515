import ReactDOM from "react-dom/client";

import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// TODO: React Strict Mode disabled due to issues with firebase fetching data via react-query
root.render(<App />);
