import { doc, getDoc, updateDoc } from "firebase/firestore";

import { Classroom, ClassroomDocument } from "~api/classrooms/types";
import { makeCollectionRef } from "~api/utils";
import { CLASSROOM_DOC_ID } from "~setup/consts/environment";
import { FIRESTORE_COLLECTIONS } from "~setup/consts/firestoreCollections";

import { LibrusClassroom } from "../types";

const importClassrooms = async (
  librusClassrooms: LibrusClassroom[]
): Promise<Classroom[]> => {
  const ref = makeCollectionRef<ClassroomDocument>(
    FIRESTORE_COLLECTIONS.Classrooms
  );

  const docRef = doc(ref, CLASSROOM_DOC_ID);

  const document = await getDoc(docRef);
  const existingClassrooms = document.data()?.rooms || [];

  const updatedClassroooms = [
    ...existingClassrooms,
    ...librusClassrooms
      .filter(
        (classroom) =>
          existingClassrooms.findIndex(
            (existing) => existing.librusId === classroom.id
          ) < 0
      )
      .map((classroom) => ({
        name: classroom.short,
        librusId: classroom.id
      }))
  ];

  await updateDoc(docRef, { rooms: updatedClassroooms });

  return updatedClassroooms;
};

export default importClassrooms;
