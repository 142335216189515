import Form from "antd/lib/form";
import useForm from "antd/lib/form/hooks/useForm";
import Col from "antd/lib/grid/col";
import Input from "antd/lib/input/Input";
import Title from "antd/lib/typography/Title";
import React, { useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import useCommonRules from "~hooks/useCommonRules";
import Button from "~components/Button";

import { TeacherFormProps, TeacherFormValues } from "./types";

const TeacherForm: React.FC<TeacherFormProps> = ({
  editMode,
  isLoading,
  onSubmit,
  initialValues
}) => {
  const [form] = useForm<TeacherFormValues>();
  const { formatMessage } = useIntl();
  const { required, maxLength } = useCommonRules();

  const handleSubmit = useCallback(
    async (data: TeacherFormValues) => {
      await onSubmit?.(data);

      form.resetFields();
    },
    [form, onSubmit]
  );

  return (
    <Col lg={{ span: 12 }} md={{ span: 24 }}>
      <Title>
        {editMode ? (
          <FormattedMessage defaultMessage="Edytuj nauczyciela" />
        ) : (
          <FormattedMessage defaultMessage="Dodaj nowego nauczyciela" />
        )}
      </Title>
      <Form
        {...{ form, initialValues }}
        name="teacher-form"
        onFinish={handleSubmit}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        autoComplete="off"
        preserve={false}
      >
        <Form.Item
          label={formatMessage({ defaultMessage: "Imię" })}
          name="firstName"
          rules={[required]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={formatMessage({ defaultMessage: "Nazwisko" })}
          name="lastName"
          rules={[required]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={formatMessage({ defaultMessage: "Inicjały" })}
          name="shortcut"
          rules={[required, maxLength(6)]}
        >
          <Input />
        </Form.Item>
        <Button htmlType="submit" type="primary" loading={isLoading}>
          {editMode ? (
            <FormattedMessage defaultMessage="Zapisz" />
          ) : (
            <FormattedMessage defaultMessage="Dodaj" />
          )}
        </Button>
      </Form>
    </Col>
  );
};

export default TeacherForm;
