import { useCallback } from "react";
import { useIntl } from "react-intl";
import notification from "antd/lib/notification";
import { doc } from "firebase/firestore";
import { useParams } from "react-router-dom";

import { Firestore } from "~setup/lib/firebase";
import { queryClient } from "~providers/react-query/consts";
import { FIRESTORE_COLLECTIONS } from "~setup/consts/firestoreCollections";
import {
  useGetClassProfileQuery,
  useUpdateClassProfileMutation
} from "~api/classProfiles";

import { ClassProfileFormValues } from "../partials/form/types";

const useEditClassProfile = () => {
  const { id } = useParams();
  const { formatMessage } = useIntl();
  const { isLoading: classProfileLoading, data: initialValues } =
    useGetClassProfileQuery(id, {
      enabled: !!id,
      select: (snapshot) => ({
        name: snapshot.data()?.name,
        extendedSubjectsRefs: snapshot
          .data()
          ?.extendedSubjects.map((subjectDoc) => subjectDoc.path),
        additionalSubjectsRefs: snapshot
          .data()
          ?.additionalSubjects.map((subjectDoc) => subjectDoc.path),
        languageGroups: snapshot
          .data()
          ?.languageGroups.map((group) => ({
            languageRefs: group.languages.map(
              (language) => language.path
            )
          }))
      })
    });

  const { mutateAsync, isLoading } = useUpdateClassProfileMutation();

  const onSubmit = useCallback(
    async (formData: ClassProfileFormValues) => {
      if (!id) return;

      await mutateAsync({
        id,
        name: formData.name,
        extendedSubjects: (formData.extendedSubjectsRefs ?? []).map(
          (ref) => doc(Firestore, ref)
        ),
        additionalSubjects: (
          formData.additionalSubjectsRefs ?? []
        ).map((ref) => doc(Firestore, ref)),
        languageGroups: (formData.languageGroups ?? []).map(
          (languageGroup) => ({
            languages: (languageGroup.languageRefs ?? []).map((ref) =>
              doc(Firestore, ref)
            )
          })
        )
      });

      await queryClient.invalidateQueries(
        FIRESTORE_COLLECTIONS.ClassProfiles
      );

      notification.success({
        message: formatMessage({
          defaultMessage: "Profil klasy został zaktualizowany"
        })
      });
    },
    [formatMessage, id, mutateAsync]
  );

  return { onSubmit, isLoading, classProfileLoading, initialValues };
};

export { useEditClassProfile };
