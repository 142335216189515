import { FIRESTORE_COLLECTIONS } from "~setup/consts/firestoreCollections";

import { makeFirestoreDocumentHook } from "../utils";

import { Metadata } from "./types";

const useGetMetadataQuery = makeFirestoreDocumentHook<Metadata>(
  FIRESTORE_COLLECTIONS.Metadata
);

export { useGetMetadataQuery };
