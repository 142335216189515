import { useCallback } from "react";

import { useCreateAdministratorMutation } from "~api/administrators";

import {
  UseAdministratorForm,
  AdministratorFormValues
} from "./types";

const useAdministratorForm: UseAdministratorForm = ({
  onSuccess
}) => {
  const { mutate, isLoading, isError } =
    useCreateAdministratorMutation({
      onSuccess
    });

  const onSubmit = useCallback(
    (data: AdministratorFormValues) => {
      mutate({ email: data.email });
    },
    [mutate]
  );

  return { onSubmit, isLoading, isError };
};

export { useAdministratorForm };
