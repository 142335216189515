import { FIRESTORE_COLLECTIONS } from "~setup/consts/firestoreCollections";

import {
  makeFirestoreDocumentHook,
  makeFirestoreDocumentMutationHook
} from "../utils";

import { TimetableDocument } from "./types";

const useGetTimetableQuery =
  makeFirestoreDocumentHook<TimetableDocument>(
    FIRESTORE_COLLECTIONS.Timetables
  );

const useUpdateTimetableMutation =
  makeFirestoreDocumentMutationHook<TimetableDocument>(
    FIRESTORE_COLLECTIONS.Timetables
  );

export { useGetTimetableQuery, useUpdateTimetableMutation };
