import React, { useState, useMemo, PropsWithChildren } from "react";
import { IntlProvider as IntlReactProvider } from "react-intl";

import { Locale } from "./types";
import { LocaleContext } from "./context";
import { DEFAULT_LOCALE, translations } from "./consts";

const IntlProvider: React.FC<PropsWithChildren<unknown>> = ({
  children
}) => {
  const [locale] = useState<Locale>(DEFAULT_LOCALE);
  const messages = useMemo(() => translations[locale], [locale]);

  const contextValue = useMemo(
    () => ({
      locale
    }),
    [locale]
  );

  return (
    <LocaleContext.Provider value={contextValue}>
      <IntlReactProvider {...{ locale, messages }}>
        {children}
      </IntlReactProvider>
    </LocaleContext.Provider>
  );
};

export default IntlProvider;
