import React from "react";

import ClassForm from "../partials/form";

import { useNewClass } from "./hooks";

const NewClassPage: React.FC = () => {
  const { onSubmit, isLoading } = useNewClass();

  return <ClassForm {...{ onSubmit, isLoading }} />;
};

export default NewClassPage;
