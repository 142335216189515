import { Switch } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

import { useMarketplaceToggler } from "./hooks";

const MarketplaceToggler = () => {
  const { isEnabled, isLoading, onChange } = useMarketplaceToggler();

  return (
    <Switch
      checked={isEnabled}
      loading={isLoading}
      onChange={onChange}
      checkedChildren={
        <>
          <CheckOutlined />
          <span style={{ marginLeft: 5 }}>ON</span>
        </>
      }
      unCheckedChildren={
        <>
          <span style={{ marginRight: 5 }}>OFF</span>
          <CloseOutlined />
        </>
      }
    />
  );
};

export default MarketplaceToggler;
