import { InfoCircleOutlined } from "@ant-design/icons";
import { Card, Typography } from "antd";
import { FormattedMessage, useIntl } from "react-intl";

import MarketplaceInfoTooltip from "~components/MarketplaceInfoTooltip";
import MarketplaceToggler from "~components/MarketplaceToggler";
import ImportDataField from "~components/ImportDataField";

const DashboardPage = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Typography.Title>
        <FormattedMessage defaultMessage="Czilo Panel" />
      </Typography.Title>
      <Card
        style={{ maxWidth: 300, marginBottom: 24 }}
        title={
          <div>
            {formatMessage({
              defaultMessage: "Giełda podręczników"
            })}
            <MarketplaceInfoTooltip>
              <InfoCircleOutlined style={{ marginLeft: 10 }} />
            </MarketplaceInfoTooltip>
          </div>
        }
      >
        <MarketplaceToggler />
      </Card>
      <Card
        style={{ maxWidth: 300 }}
        title={
          <div>
            {formatMessage({
              defaultMessage: "Import danych z systemu Librus"
            })}
          </div>
        }
      >
        <ImportDataField />
      </Card>
    </>
  );
};

export default DashboardPage;
