import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";

import {
  useGetSaleOfferQuery,
  useSaleOfferDeletion
} from "~api/sale-offers";
import useBookTypes from "~hooks/useBookTypes";
import useConfirmModal from "~hooks/useConfirmModal";
import { ROUTES } from "~router/consts";
import formatPrice from "~utils/formatPrice";

const useSaleOfferDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const bookTypes = useBookTypes();
  const { isLoading, data: saleOffer } = useGetSaleOfferQuery(id, {
    enabled: !!id,
    select: (snapshot) => snapshot.data()
  });
  const { mutate } = useSaleOfferDeletion({
    onSuccess: () => {
      navigate(ROUTES.SaleOffers);
    }
  });
  const showConfirmModal = useConfirmModal();

  const onDeleteClick = useCallback(() => {
    if (!id) return;

    showConfirmModal({
      onOk: () => mutate({ id }),
      title: formatMessage({
        defaultMessage: "Czy na pewno chcesz usunąć ogłoszenie?"
      })
    });
  }, [formatMessage, id, mutate, showConfirmModal]);

  const items = useMemo(
    () =>
      saleOffer
        ? [
            {
              key: "desc",
              title: formatMessage({
                defaultMessage: "Opis"
              }),
              content: saleOffer.description || "-"
            },
            {
              key: "details",
              title: formatMessage({
                defaultMessage: "Szczegóły"
              }),
              items: [
                {
                  key: "subject",
                  title: formatMessage({
                    defaultMessage: "Przedmiot"
                  }),
                  content: saleOffer.subject
                },
                {
                  key: "class",
                  title: formatMessage({
                    defaultMessage: "Klasa"
                  }),
                  content: saleOffer.class
                },
                {
                  key: "type",
                  title: formatMessage({
                    defaultMessage: "Typ książki"
                  }),
                  content: bookTypes[saleOffer.bookType]
                },
                {
                  key: "price",
                  title: formatMessage({
                    defaultMessage: "Cena"
                  }),
                  content: formatPrice(saleOffer.price)
                }
              ]
            },
            {
              key: "contact",
              title: formatMessage({
                defaultMessage: "Kontakt"
              }),
              items: [
                {
                  key: "phone",
                  title: formatMessage({
                    defaultMessage: "Numer telefonu"
                  }),
                  content: saleOffer.seller.phone || undefined
                },
                {
                  key: "email",
                  title: formatMessage({
                    defaultMessage: "Adres email"
                  }),
                  content: saleOffer.seller.email
                },
                {
                  key: "seller",
                  title: formatMessage({
                    defaultMessage: "Dodane przez"
                  }),
                  content: saleOffer.seller.name
                }
              ]
            }
          ]
        : [],
    [saleOffer, formatMessage, bookTypes]
  );

  return { saleOffer, items, isLoading, onDeleteClick };
};

export { useSaleOfferDetails };
