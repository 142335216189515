import pl from "~translations/locales/pl.json";
import { DEFAULT_LOCALE as GlobalDefaultLocale } from "~setup/consts/environment";

import { Locale } from "./types";

const LOCALES: Locale[] = ["pl"];

const DEFAULT_LOCALE = (GlobalDefaultLocale as Locale) || "pl";

const translations = {
  pl
};

export { LOCALES, DEFAULT_LOCALE, translations };
