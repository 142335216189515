import { useCallback } from "react";
import { useIntl } from "react-intl";
import notification from "antd/lib/notification";
import { doc } from "firebase/firestore";

import { useCreateSubjectMutation } from "~api/subjects";
import { Firestore } from "~setup/lib/firebase";
import { queryClient } from "~providers/react-query/consts";
import { FIRESTORE_COLLECTIONS } from "~setup/consts/firestoreCollections";

import { SubjectFormValues } from "../partials/form/types";

const useNewSubject = () => {
  const { formatMessage } = useIntl();
  const { mutateAsync, isLoading } = useCreateSubjectMutation();

  const onSubmit = useCallback(
    async (data: SubjectFormValues) => {
      await mutateAsync({
        name: data.name,
        shortcut: data.shortcut,
        teachers: data.teacherRefs.map((teacherRef) =>
          doc(Firestore, teacherRef)
        )
      });

      await queryClient.invalidateQueries(
        FIRESTORE_COLLECTIONS.Subjects
      );

      notification.success({
        message: formatMessage({
          defaultMessage: "Przedmiot został dodany"
        })
      });
    },
    [formatMessage, mutateAsync]
  );

  return { onSubmit, isLoading };
};

export { useNewSubject };
