import { GoogleAuthProvider } from "firebase/auth";

import { GOOGLE_AUTH_DOMAIN } from "~setup/consts/environment";

const googleProvider = new GoogleAuthProvider();

googleProvider.setCustomParameters({
  hd: GOOGLE_AUTH_DOMAIN
});

export { googleProvider };
