import { createContext, useContext } from "react";

import { TimetableState } from "./types";

const TimetableContext = createContext<TimetableState>(
  {} as TimetableState
);

const useTimetable = () => useContext(TimetableContext);

export { TimetableContext, useTimetable };
