import { useCallback } from "react";
import { useIntl } from "react-intl";
import notification from "antd/lib/notification";
import { Timestamp } from "firebase/firestore";

import { Event } from "~api/events/types";
import { useCreateEventMutation } from "~api/events";
import { queryClient } from "~providers/react-query/consts";
import { FIRESTORE_COLLECTIONS } from "~setup/consts/firestoreCollections";

const useNewEvent = () => {
  const { formatMessage } = useIntl();
  const { mutateAsync, isLoading } = useCreateEventMutation();

  const onSubmit = useCallback(
    async (data: Event) => {
      await mutateAsync({
        ...data,
        createdAt: Timestamp.fromDate(new Date()),
        updatedAt: Timestamp.fromDate(new Date())
      });
      await queryClient.invalidateQueries(
        FIRESTORE_COLLECTIONS.Events
      );

      notification.success({
        message: formatMessage({
          defaultMessage: "Wydarzenie zostało utworzone"
        })
      });
    },
    [formatMessage, mutateAsync]
  );

  return { onSubmit, isLoading };
};

export { useNewEvent };
