import Spin from "antd/lib/spin";

import EventForm from "../partials/form";

import { useEditEvent } from "./hooks";

const EditEventPage: React.FC = () => {
  const { onSubmit, isLoading, eventLoading, initialValues } =
    useEditEvent();

  if (eventLoading) return <Spin size="large" />;

  return (
    <EventForm editMode {...{ onSubmit, isLoading, initialValues }} />
  );
};

export default EditEventPage;
