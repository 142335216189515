import { Tooltip } from "antd";
import { PropsWithChildren } from "react";
import { useIntl } from "react-intl";

const MarketplaceInfoTooltip = ({
  children
}: PropsWithChildren<unknown>) => {
  const { formatMessage } = useIntl();

  return (
    <Tooltip
      title={formatMessage({
        defaultMessage:
          "Wyłączenie giełdy spowoduje brak możliwości dodawania nowych ogłoszeń. Istniejące ogłoszenia będą nadal widoczne."
      })}
    >
      {children}
    </Tooltip>
  );
};

export default MarketplaceInfoTooltip;
