import notification from "antd/lib/notification";
import moment from "moment";
import { useCallback } from "react";
import { useIntl } from "react-intl";

import {
  useGetLessonTimesQuery,
  useLessonTimesMutation
} from "~api/lesson-times";
import { LESSON_TIMES_ID } from "~setup/consts/environment";
import { DEFAULT_TIME_FORMAT, formatTime } from "~utils/date-utils";

import { LessonTimesFormValues } from "./partials/form/types";

const useLessonTimes = () => {
  const { formatMessage } = useIntl();
  const { data, isLoading } =
    useGetLessonTimesQuery<LessonTimesFormValues>(LESSON_TIMES_ID, {
      select: (snapshot) => {
        const { lessonTimes } = snapshot?.data() || {};

        return {
          lessonTimes: lessonTimes?.map(({ from, to }) => [
            moment(from, DEFAULT_TIME_FORMAT),
            moment(to, DEFAULT_TIME_FORMAT)
          ])
        };
      }
    });

  const { isLoading: isUpdating, mutateAsync: updateLessonTimes } =
    useLessonTimesMutation();

  const onSubmit = useCallback(
    async ({ lessonTimes }: LessonTimesFormValues) => {
      const times =
        lessonTimes?.map(([from, to]) => ({
          from: formatTime(from),
          to: formatTime(to)
        })) || [];

      await updateLessonTimes({
        id: LESSON_TIMES_ID,
        lessonTimes: times
      });

      notification.success({
        message: formatMessage({
          defaultMessage: "Godziny lekcyjne zostały zaktualizowane!"
        })
      });
    },
    [formatMessage, updateLessonTimes]
  );

  return { initialValues: data, onSubmit, isLoading, isUpdating };
};

export { useLessonTimes };
