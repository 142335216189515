import ClassProfileForm from "../partials/form";

import { useNewClassProfile } from "./hooks";

const NewClassProfilePage = () => {
  const { isLoading, onSubmit } = useNewClassProfile();

  return <ClassProfileForm {...{ isLoading, onSubmit }} />;
};

export default NewClassProfilePage;
