import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import { User as FirebaseUser } from "firebase/auth";

import { useGetMetadataQuery } from "~api/metadata";
import { FirebaseAuth } from "~setup/lib/firebase";

import { AuthContext } from "./context";
import {
  isValidDomainUser,
  logoutUser,
  normalizeFirebaseUser
} from "./utils";

const AuthProvider: React.FC<PropsWithChildren<unknown>> = ({
  children
}) => {
  const [user, setUser] = useState<FirebaseUser>();
  const [isAuthLoading, setAuthLoading] = useState(true);

  const signOut = useCallback(() => {
    logoutUser();
    setUser(undefined);
  }, []);

  const checkClaims = useCallback(
    async (authState = user) => {
      const idToken = await authState?.getIdTokenResult(true);

      if (idToken?.claims.admin === undefined) return;

      if (!idToken?.claims?.admin) {
        // Logout user if it's not an admin
        signOut();
      }

      setAuthLoading(false);
    },
    [signOut, user]
  );

  useGetMetadataQuery(user?.uid ?? "---", {
    enabled: !!user?.uid && isAuthLoading,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: 0,
    notifyOnChangeProps: ["data"],
    onSuccess: (snapshot) => {
      if (snapshot.metadata.fromCache) return;

      checkClaims();
    }
  });

  useEffect(() => {
    const unsubscribeAuthState = FirebaseAuth.onAuthStateChanged(
      async (authState) => {
        // Logout user if it's not associated with domain
        if (!authState || !isValidDomainUser(authState)) {
          signOut();
          setAuthLoading(false);

          return;
        }

        setUser(authState);
        checkClaims(authState);
      }
    );

    return () => {
      unsubscribeAuthState();
    };
  }, [checkClaims, signOut]);

  const contextValues = useMemo(
    () => ({
      user: normalizeFirebaseUser(user),
      isLoggedIn: !!user && !isAuthLoading,
      isAuthLoading,
      signOut
    }),
    [isAuthLoading, signOut, user]
  );

  return (
    <AuthContext.Provider value={contextValues}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
