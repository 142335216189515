import { useIntl } from "react-intl";
import { RuleObject, Rule } from "antd/lib/form";

type RuleResolver<T> = (params: T) => RuleObject;

type UseCommonRulesReturnType = {
  positiveInteger: Rule;
  required: Rule;
  email: Rule;
  maxLength: RuleResolver<number>;
};

const useCommonRules = (): UseCommonRulesReturnType => {
  const { formatMessage } = useIntl();

  return {
    positiveInteger: {
      pattern: /^[1-9]\d*$/,
      message: formatMessage({
        defaultMessage: "Liczba musi być dodatnią liczbą całkowitą."
      })
    },
    required: {
      required: true,
      message: formatMessage({ defaultMessage: "Pole jest wymagane" })
    },
    email: {
      type: "email",
      message: formatMessage({
        defaultMessage: "To nie jest poprawny adres e-mail"
      })
    },
    maxLength: (max: number) => ({
      max,
      message: formatMessage(
        {
          defaultMessage: "Pole powinno mieć maksymalnie {max} znaków"
        },
        { max }
      )
    })
  };
};

export default useCommonRules;
