import React from "react";
import Spin from "antd/lib/spin";

import TeacherForm from "../partials/form";

import { useEditTeacher } from "./hooks";

const EditTeacherPage: React.FC = () => {
  const { isTeacherLoading, teacherData, isLoading, onUpdate } =
    useEditTeacher();

  if (isTeacherLoading) return <Spin size="large" />;

  return (
    <TeacherForm
      editMode
      onSubmit={onUpdate}
      initialValues={teacherData}
      {...{ isLoading }}
    />
  );
};

export default EditTeacherPage;
