import { TableLocale } from "antd/lib/table/interface";
import { useMemo } from "react";
import { useIntl } from "react-intl";

const useTableLocale = () => {
  const { formatMessage } = useIntl();

  return useMemo<TableLocale>(
    () => ({
      cancelSort: formatMessage({
        defaultMessage: "Anuluj sortowanie"
      }),
      collapse: formatMessage({ defaultMessage: "Zwiń" }),
      emptyText: formatMessage({ defaultMessage: "Brak danych" }),
      expand: formatMessage({ defaultMessage: "Rozwiń" }),
      filterCheckall: formatMessage({
        defaultMessage: "Zaznacz wszystkie"
      }),
      filterConfirm: formatMessage({ defaultMessage: "Ok" }),
      filterEmptyText: formatMessage({
        defaultMessage: "Brak filtrów"
      }),
      filterReset: formatMessage({ defaultMessage: "Zresetuj" }),
      filterSearchPlaceholder: formatMessage({
        defaultMessage: "Szukaj w filtrach"
      }),
      filterTitle: formatMessage({ defaultMessage: "Filtry" }),
      selectAll: formatMessage({
        defaultMessage: "Zaznacz wszystkie"
      }),
      selectInvert: formatMessage({
        defaultMessage: "Odwróc wyniki na stronie"
      }),
      selectionAll: formatMessage({
        defaultMessage: "Zaznacz wszystko rekordy"
      }),
      selectNone: formatMessage({
        defaultMessage: "Odznacz wszystko"
      }),
      sortTitle: formatMessage({ defaultMessage: "Posortuj" }),
      triggerAsc: formatMessage({
        defaultMessage: "Posortuj rosnąco"
      }),
      triggerDesc: formatMessage({
        defaultMessage: "Posortuj malejąco"
      })
    }),
    [formatMessage]
  );
};

export default useTableLocale;
