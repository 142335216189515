import { FormattedMessage } from "react-intl";
import Layout from "antd/lib/layout/layout";
import Title from "antd/lib/typography/Title";
import GooglePlusOutlined from "@ant-design/icons/lib/icons/GooglePlusOutlined";

import { ReactComponent as Logo } from "~assets/images/logo/full.svg";
import Button from "~components/Button";
import { signInWithGoogle } from "~providers/auth/utils";

const LoginPage = () => (
  <Layout className="login-wrapper">
    <Logo className="logo" />
    <div className="login-container">
      <Title style={{ textAlign: "center" }}>
        <FormattedMessage defaultMessage="Logowanie do panelu Czilo" />
      </Title>
      <Button
        size="large"
        style={{ padding: "0 40px", height: 60 }}
        icon={<GooglePlusOutlined />}
        onClick={signInWithGoogle}
        ghost={false}
      >
        <FormattedMessage
          tagName="span"
          defaultMessage="Zaloguj się przez Google"
        />
      </Button>
    </div>
    <div />
  </Layout>
);

export default LoginPage;
