import { useMemo } from "react";
import { useIntl } from "react-intl";

import { BookType } from "~api/sale-offers/consts";

const useBookTypes = () => {
  const { formatMessage } = useIntl();

  return useMemo(
    () => ({
      [BookType.COMPENDIUM]: formatMessage({
        defaultMessage: "Repetytorium"
      }),
      [BookType.COURSEBOOK]: formatMessage({
        defaultMessage: "Podręcznik"
      }),
      [BookType.WORKBOOK]: formatMessage({
        defaultMessage: "Ćwiczenia"
      })
    }),
    [formatMessage]
  );
};

export default useBookTypes;
