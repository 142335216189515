import {
  ProviderProps,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import debounce from "lodash/debounce";

import { useGetLessonTimesQuery } from "~api/lesson-times";
import { LessonTime } from "~api/lesson-times/types";
import { WorkingDay } from "~hooks/useDays";
import { LESSON_TIMES_ID } from "~setup/consts/environment";
import { TimetableDocument } from "~api/timetable/types";
import {
  useGetTimetableQuery,
  useUpdateTimetableMutation
} from "~api/timetable";

import { TimetableContext } from "./context";
import { TimetableProviderProps } from "./types";

const TimetableProvider: React.FC<
  ProviderProps<TimetableProviderProps>
> = ({ children, value: { classId, autosave = false } }) => {
  const [isSyncing, setIsSyncing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedDay, setSelectedDay] = useState(WorkingDay.Monday);
  const [timetable, setTimetable] = useState<TimetableDocument>({
    mon: [],
    tue: [],
    wed: [],
    thu: [],
    fri: []
  });

  const { mutateAsync } = useUpdateTimetableMutation(
    {},
    {
      onSettled: () => {
        setIsSyncing(false);
      }
    }
  );

  const { isLoading: isTimetableLoading, data: lessonData } =
    useGetTimetableQuery(classId, {
      enabled: !!classId
    });

  const { isLoading: isLoadingLessonTimes, data: lessonTimes = [] } =
    useGetLessonTimesQuery<LessonTime[]>(LESSON_TIMES_ID, {
      select: (snapshot) => snapshot.data()?.lessonTimes ?? []
    });

  useEffect(() => {
    if (!isLoading) {
      // It was already transformed
      return;
    }

    if (isTimetableLoading || isLoadingLessonTimes) {
      return;
    }

    const savedLesson = lessonData?.data();

    if (savedLesson) {
      setTimetable(savedLesson);
      setIsLoading(false);

      return;
    }

    const emptyArray = lessonTimes.map(() => ({ lessons: [] })) || [];

    setTimetable({
      mon: emptyArray,
      tue: emptyArray,
      wed: emptyArray,
      thu: emptyArray,
      fri: emptyArray
    });
    setIsLoading(false);
  }, [
    isTimetableLoading,
    isLoadingLessonTimes,
    lessonData,
    lessonTimes,
    isLoading
  ]);

  const onChangeDay = useCallback((day: WorkingDay) => {
    setSelectedDay(day);
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSaveData = useCallback(
    debounce(async (data: TimetableDocument) => {
      await mutateAsync({
        id: classId,
        ...data
      });

      setIsSyncing(false);
    }, 500),
    [classId, mutateAsync]
  );

  const saveData = useCallback(
    (data: TimetableDocument) => {
      setIsSyncing(true);
      debouncedSaveData(data);
    },
    [debouncedSaveData]
  );

  useEffect(() => {
    if (autosave && timetable && !isLoading) {
      saveData(timetable);
    }
  }, [autosave, isLoading, saveData, timetable]);

  const contextValue = useMemo(
    () => ({
      classId,
      timetable,
      selectedDay,
      isLoading,
      lessonTimes,
      autosave,
      isSyncing,
      saveData,
      onChangeDay,
      setTimetable
    }),
    [
      autosave,
      classId,
      isLoading,
      isSyncing,
      lessonTimes,
      onChangeDay,
      saveData,
      selectedDay,
      timetable
    ]
  );

  return (
    <TimetableContext.Provider value={contextValue}>
      {children}
    </TimetableContext.Provider>
  );
};

export default TimetableProvider;
