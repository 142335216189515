export const FIRESTORE_COLLECTIONS = {
  Administrators: "administrators",
  Teachers: "teachers",
  Classes: "classes",
  ClassProfiles: "classProfiles",
  Subjects: "subjects",
  LessonTimes: "lessonTimes",
  Metadata: "metadata",
  Timetables: "timetables",
  Classrooms: "classrooms",
  Constants: "constants",
  Events: "events",
  Marketplace: "marketplace"
};
