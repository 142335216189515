import Divider from "antd/lib/divider";
import Title from "antd/lib/typography/Title";
import { FormattedMessage } from "react-intl";

import PointsForm from "./partials/Points";
import ScoredSubjectsForm from "./partials/ScoredSubjects";

const RecruitmentPage: React.FC = () => (
  <>
    <Title level={1}>
      <FormattedMessage defaultMessage="Punktacja" />
    </Title>
    <PointsForm />
    <Divider />
    <Title level={1}>
      <FormattedMessage defaultMessage="Przedmioty punktowane" />
    </Title>
    <ScoredSubjectsForm />
  </>
);

export default RecruitmentPage;
