import { BrowserRouter, Route, Routes } from "react-router-dom";

import LoginPage from "~pages/Login";
import DashboardPage from "~pages/Dashboard";
import ClassesListPage from "~pages/Classes/list";
import NewClassPage from "~pages/Classes/new";
import EditClassPage from "~pages/Classes/edit";
import NewTeacherPage from "~pages/Teachers/new";
import EditTeacherPage from "~pages/Teachers/edit";
import TeachersListPage from "~pages/Teachers/list";
import AdministratorsPage from "~pages/Administrators";
import ClassProfilesPage from "~pages/ClassProfiles/list";
import NewClassProfilePage from "~pages/ClassProfiles/new";
import EditClassProfilePage from "~pages/ClassProfiles/edit";
import SubjectsListPage from "~pages/Subjects/list";
import NewSubjectPage from "~pages/Subjects/new";
import EditSubjectPage from "~pages/Subjects/edit";
import LessonTimesPage from "~pages/LessonTimes";
import ClassTimetablePage from "~pages/ClassTimetable/ClassTimetableWrapper";
import EventsListPage from "~pages/Events/list";
import NewEventPage from "~pages/Events/new";
import EditEventPage from "~pages/Events/edit";
import RecruitmentPage from "~pages/Recruitment";
import { EventType } from "~pages/Events/list/consts";
import SaleOffersListPage from "~pages/SaleOffers/list";
import SaleOfferDetailsPage from "~pages/SaleOffers/details";

import { ROUTES } from "./consts";
import PublicRoutes from "./PublicRoutes";
import ProtectedRoutes from "./ProtectedRoutes";

const Router: React.FC = () => (
  <BrowserRouter>
    <Routes>
      <Route element={<ProtectedRoutes />}>
        <Route path={ROUTES.Root} element={<DashboardPage />} />
        <Route
          path={ROUTES.Administrators}
          element={<AdministratorsPage />}
        />
        <Route
          path={ROUTES.Teachers}
          element={<TeachersListPage />}
        />
        <Route
          path={ROUTES.NewTeacher}
          element={<NewTeacherPage />}
        />
        <Route
          path={ROUTES.EditTeacher}
          element={<EditTeacherPage />}
        />
        <Route path={ROUTES.Classes} element={<ClassesListPage />} />
        <Route path={ROUTES.NewClass} element={<NewClassPage />} />
        <Route path={ROUTES.EditClass} element={<EditClassPage />} />

        <Route
          path={ROUTES.Recruitment}
          element={<RecruitmentPage />}
        />
        <Route
          path={ROUTES.ClassTimetable}
          element={<ClassTimetablePage />}
        />
        <Route
          path={ROUTES.ClassProfiles}
          element={<ClassProfilesPage />}
        />
        <Route
          path={ROUTES.NewClassProfile}
          element={<NewClassProfilePage />}
        />
        <Route
          path={ROUTES.EditClassProfile}
          element={<EditClassProfilePage />}
        />
        <Route
          path={ROUTES.Subjects}
          element={<SubjectsListPage />}
        />
        <Route
          path={ROUTES.NewSubject}
          element={<NewSubjectPage />}
        />
        <Route
          path={ROUTES.EditSubject}
          element={<EditSubjectPage />}
        />
        <Route
          path={ROUTES.LessonTimes}
          element={<LessonTimesPage />}
        />
        <Route
          path={ROUTES.Events}
          element={
            <EventsListPage
              key={EventType.ACTIVE}
              eventType={EventType.ACTIVE}
            />
          }
        />
        <Route
          path={ROUTES.ArchiveEvents}
          element={
            <EventsListPage
              key={EventType.ARCHIVE}
              eventType={EventType.ARCHIVE}
            />
          }
        />
        <Route path={ROUTES.NewEvent} element={<NewEventPage />} />
        <Route path={ROUTES.EditEvent} element={<EditEventPage />} />
        <Route
          path={ROUTES.SaleOffers}
          element={<SaleOffersListPage />}
        />
        <Route
          path={ROUTES.SaleOfferDetails}
          element={<SaleOfferDetailsPage />}
        />
      </Route>
      <Route element={<PublicRoutes />}>
        <Route path={ROUTES.Login} element={<LoginPage />} />
      </Route>
    </Routes>
  </BrowserRouter>
);

export default Router;
