import Form from "antd/lib/form";
import Col from "antd/lib/grid/col";
import Row from "antd/lib/grid/row";
import Input from "antd/lib/input/Input";
import Select from "antd/lib/select";
import Title from "antd/lib/typography/Title";
import { FormattedMessage, useIntl } from "react-intl";
import DatePicker from "antd/lib/date-picker";
import { InputNumber } from "antd";
import { useWatch } from "antd/lib/form/Form";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build";

import useCommonRules from "~hooks/useCommonRules";
import Button from "~components/Button";

import { EventFormProps } from "./types";
import { useEventForm } from "./hooks";

const EventForm: React.FC<EventFormProps> = ({
  editMode,
  isLoading,
  onSubmit,
  initialValues
}) => {
  const { formatMessage } = useIntl();
  const { required } = useCommonRules();
  const {
    form,
    categories,
    categoriesLoading,
    classes,
    classesLoading,
    selectedCategory,
    handleSubmit
  } = useEventForm(onSubmit, editMode);
  const startDate = useWatch<moment.Moment>("startDate", form);
  const endDate = useWatch<moment.Moment>("endDate", form);

  return (
    <Col lg={{ span: 12 }} md={{ span: 24 }}>
      <Title>
        {editMode ? (
          <FormattedMessage defaultMessage="Edytuj wydarzenie" />
        ) : (
          <FormattedMessage defaultMessage="Dodaj nowe wydarzenie" />
        )}
      </Title>
      <Form
        {...{ form, initialValues }}
        name="event-form"
        onFinish={handleSubmit}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        autoComplete="off"
        preserve={false}
      >
        <Form.Item
          label={formatMessage({
            defaultMessage: "Nazwa wydarzenia"
          })}
          name="title"
          rules={[required]}
        >
          <Input />
        </Form.Item>
        <Row gutter={[20, 0]}>
          <Col span={12}>
            <Form.Item
              label={formatMessage({
                defaultMessage: "Data i godzina rozpoczęcia"
              })}
              name="startDate"
              rules={[required]}
            >
              <DatePicker
                {...(endDate
                  ? {
                      disabledDate: (date) => date.isAfter(endDate)
                    }
                  : {})}
                className="w-100"
                showTime
                format="YYYY-MM-DD HH:mm"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={formatMessage({
                defaultMessage: "Data i godzina zakończenia"
              })}
              name="endDate"
            >
              <DatePicker
                {...(startDate
                  ? {
                      disabledDate: (date) => date.isBefore(startDate)
                    }
                  : {})}
                className="w-100"
                showTime
                format="YYYY-MM-DD HH:mm"
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label={formatMessage({ defaultMessage: "Kategoria" })}
          name="categoryId"
          rules={[required]}
        >
          <Select
            onChange={() => {
              form.setFieldsValue({
                subcategoryId: undefined
              });
            }}
            loading={categoriesLoading}
            disabled={categoriesLoading}
            options={categories.map(({ color, name, id }) => ({
              label: (
                <div className="categories-selection">
                  {color && (
                    <span
                      className="dot"
                      style={{ backgroundColor: color }}
                    />
                  )}
                  {name}
                </div>
              ),
              value: id
            }))}
          />
        </Form.Item>
        {selectedCategory && (
          <Form.Item
            label={formatMessage({ defaultMessage: "Podkategoria" })}
            name="subcategoryId"
          >
            <Select
              loading={categoriesLoading}
              disabled={!selectedCategory}
              options={selectedCategory?.subcategories.map(
                ({ name, id }) => ({
                  label: name,
                  value: id
                })
              )}
            />
          </Form.Item>
        )}
        <Row gutter={[20, 0]}>
          <Col span={12}>
            <Form.Item
              label={formatMessage({
                defaultMessage: "Minimalny wiek"
              })}
              name="minAge"
            >
              <InputNumber className="w-100" min={10} max={30} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={formatMessage({
                defaultMessage: "Maksymalny wiek"
              })}
              name="maxAge"
            >
              <InputNumber className="w-100" min={10} max={30} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label={formatMessage({ defaultMessage: "Klasy" })}
          name="classesIds"
        >
          <Select
            mode="multiple"
            loading={classesLoading}
            disabled={classesLoading}
            options={classes.map(({ name, id }) => ({
              label: name,
              value: id
            }))}
            filterOption={(input, option) =>
              (option?.label ?? "")
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item
          label={formatMessage({ defaultMessage: "Opis wydarzenia" })}
          name="description"
          valuePropName="data"
          getValueFromEvent={(_, editor: Editor) => {
            const data = editor.getData();

            return data;
          }}
        >
          <CKEditor editor={Editor} />
        </Form.Item>
        <Button htmlType="submit" type="primary" loading={isLoading}>
          {editMode ? (
            <FormattedMessage defaultMessage="Zapisz" />
          ) : (
            <FormattedMessage defaultMessage="Dodaj" />
          )}
        </Button>
      </Form>
    </Col>
  );
};

export default EventForm;
