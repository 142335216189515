const parseBoolean = (value: string | boolean | undefined | null) => {
  if (typeof value === "boolean") return value;

  if (value?.toLowerCase() === "true") {
    return true;
  }

  if (value?.toLowerCase() === "false") {
    return false;
  }

  return undefined;
};

export default parseBoolean;
