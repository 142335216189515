import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Input, Table, Typography } from "antd";

import Button from "~components/Button";
import { ROUTES } from "~router/consts";
import useTableLocale from "~hooks/useTableLocale";

import { useEvents } from "./hooks";
import { EventListProps } from "./types";

const EventsListPage = ({ eventType }: EventListProps) => {
  const navigate = useNavigate();
  const {
    columns,
    dataSource,
    loading,
    searchQuery,
    onSearchQueryChanged
  } = useEvents(eventType);
  const locale = useTableLocale();
  const { formatMessage } = useIntl();

  return (
    <>
      <div className="list-header">
        <Typography.Title>
          <FormattedMessage defaultMessage="Wydarzenia" />
        </Typography.Title>
        <Button onClick={() => navigate(ROUTES.NewEvent)}>
          <FormattedMessage defaultMessage="Dodaj wydarzenie" />
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          gap: 10,
          marginTop: 20,
          marginBottom: 20
        }}
      >
        <Input
          placeholder={formatMessage({
            defaultMessage: "Wyszukaj po nazwie"
          })}
          onChange={onSearchQueryChanged}
          style={{ flex: 3 }}
          value={searchQuery}
        />
      </div>
      <Table
        rowKey="id"
        {...{ columns, loading, dataSource, locale }}
      />
    </>
  );
};

export default EventsListPage;
