export const readFile = (file: File): Promise<string> =>
  new Promise((resolve) => {
    const reader = new FileReader();

    reader.readAsText(file, "CP1250");

    reader.onload = (evt) => {
      if (!evt.target) return;

      const { result } = evt.target;

      resolve(result as string);
    };
  });
