export const DEFAULT_REQUIRED_SUBJECTS = [
  "Język polski",
  "Matematyka"
];
export const DEFAULT_ADDITIONAL_SUBJECTS = [
  "Fizyka",
  "Chemia",
  "Biologia",
  "Geografia",
  "Język angielski",
  "Język obcy",
  "Informatyka",
  "Historia",
  "Wiedza o społeczeństwie"
];
