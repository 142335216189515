import { createContext, useContext } from "react";

import { LocaleState } from "./types";
import { DEFAULT_LOCALE } from "./consts";

const LocaleContext = createContext<LocaleState>({
  locale: DEFAULT_LOCALE
});

const useLocale = () => useContext(LocaleContext);

export { LocaleContext, useLocale };
