import { FIRESTORE_COLLECTIONS } from "~setup/consts/firestoreCollections";

import { makeFirestoreDocumentHook } from "../utils";

import { ClassroomDocument } from "./types";

const useGetClassroomsQuery =
  makeFirestoreDocumentHook<ClassroomDocument>(
    FIRESTORE_COLLECTIONS.Classrooms
  );

export { useGetClassroomsQuery };
