import { FIRESTORE_COLLECTIONS } from "~setup/consts/firestoreCollections";

import {
  makeFirestoreCollectionMutationHook,
  makeFirestoreDocumentDeletionHook,
  makeFirestoreDocumentHook,
  makeFirestoreDocumentMutationHook,
  makeFirestoreQueryHook
} from "../utils";

import { Event } from "./types";

const useGetEventsQuery = makeFirestoreQueryHook<Event>(
  FIRESTORE_COLLECTIONS.Events
);

const useGetEventQuery = makeFirestoreDocumentHook<Event>(
  FIRESTORE_COLLECTIONS.Events
);

const useEventDeletion = makeFirestoreDocumentDeletionHook(
  FIRESTORE_COLLECTIONS.Events
);

const useCreateEventMutation =
  makeFirestoreCollectionMutationHook<Event>(
    FIRESTORE_COLLECTIONS.Events
  );

const useUpdateEventMutation =
  makeFirestoreDocumentMutationHook<Event>(
    FIRESTORE_COLLECTIONS.Events
  );

export {
  useGetEventsQuery,
  useGetEventQuery,
  useEventDeletion,
  useCreateEventMutation,
  useUpdateEventMutation
};
