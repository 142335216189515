import parseBoolean from "~utils/parseBoolean";

const NODE_ENV = (() => process.env.NODE_ENV)();
const DEFAULT_LOCALE = (() => process.env.DEFAULT_LOCALE)();

const FIREBASE_USE_EMULATORS = (() =>
  parseBoolean(process.env.REACT_APP_FIREBASE_USE_EMULATORS))();
const FIREBASE_USE_CACHE = (() =>
  parseBoolean(process.env.REACT_APP_FIREBASE_USE_CACHE))();

const FIREBASE_API_KEY = (() => {
  if (!process.env.REACT_APP_FIREBASE_API_KEY) {
    throw new Error(
      "Missing environment variable - FIREBASE_API_KEY"
    );
  }

  return process.env.REACT_APP_FIREBASE_API_KEY;
})();

const FIREBASE_PROJECT_ID = (() => {
  if (!process.env.REACT_APP_FIREBASE_PROJECT_ID) {
    throw new Error(
      "Missing environment variable - FIREBASE_PROJECT_ID"
    );
  }

  return process.env.REACT_APP_FIREBASE_PROJECT_ID;
})();

const FIREBASE_APP_ID = (() => {
  if (!process.env.REACT_APP_FIREBASE_APP_ID) {
    throw new Error("Missing environment variable - FIREBASE_APP_ID");
  }

  return process.env.REACT_APP_FIREBASE_APP_ID;
})();

const FIREBASE_STORAGE_BUCKET = (() => {
  if (!process.env.REACT_APP_FIREBASE_STORAGE_BUCKET) {
    throw new Error(
      "Missing environment variable - FIREBASE_STORAGE_BUCKET"
    );
  }

  return process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
})();

const FIREBASE_AUTH_DOMAIN = (() => {
  if (!process.env.REACT_APP_FIREBASE_AUTH_DOMAIN) {
    throw new Error(
      "Missing environment variable - FIREBASE_AUTH_DOMAIN"
    );
  }

  return process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
})();

const FIREBASE_MESSAGING_SENDER_ID = (() => {
  if (!process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID) {
    throw new Error(
      "Missing environment variable - FIREBASE_MESSAGING_SENDER_ID"
    );
  }

  return process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
})();

const GOOGLE_AUTH_DOMAIN = (() => {
  if (!process.env.REACT_APP_GOOGLE_AUTH_DOMAIN) {
    throw new Error(
      "Missing environment variable - GOOGLE_AUTH_DOMAIN"
    );
  }

  return process.env.REACT_APP_GOOGLE_AUTH_DOMAIN;
})();

const LESSON_TIMES_ID = (() => {
  if (!process.env.REACT_APP_LESSON_TIMES_ID) {
    throw new Error("Missing environment variable - LESSON_TIMES_ID");
  }

  return process.env.REACT_APP_LESSON_TIMES_ID;
})();

const CLASSROOM_DOC_ID = (() => {
  if (!process.env.REACT_APP_CLASSROOM_DOC_ID) {
    throw new Error(
      "Missing environment variable - CLASSROOM_DOC_ID"
    );
  }

  return process.env.REACT_APP_CLASSROOM_DOC_ID;
})();

export {
  NODE_ENV,
  DEFAULT_LOCALE,
  FIREBASE_API_KEY,
  FIREBASE_APP_ID,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
  GOOGLE_AUTH_DOMAIN,
  LESSON_TIMES_ID,
  CLASSROOM_DOC_ID,
  FIREBASE_USE_EMULATORS,
  FIREBASE_USE_CACHE
};
