import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload } from "antd";
import { FormattedMessage } from "react-intl";

import { useImportDataField } from "./hooks";

const ImportDataField = () => {
  const { isImporting, beforeUpload, onChange } =
    useImportDataField();

  return (
    <Upload
      accept="text/xml"
      multiple={false}
      showUploadList={false}
      {...{ beforeUpload, onChange }}
    >
      <Button loading={isImporting} icon={<UploadOutlined />}>
        <FormattedMessage defaultMessage="Zaimportuj dane " />
      </Button>
    </Upload>
  );
};

export default ImportDataField;
