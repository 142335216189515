import {
  signInWithRedirect,
  User as FirebaseUser
} from "firebase/auth";

import { GOOGLE_AUTH_DOMAIN } from "~setup/consts/environment";
import { FirebaseAuth } from "~setup/lib/firebase";

import { User } from "./types";
import { googleProvider } from "./consts";

const normalizeFirebaseUser = (
  user?: FirebaseUser
): User | undefined =>
  user
    ? {
        uid: user.uid,
        email: user.email ?? "",
        name: user.displayName ?? ""
      }
    : undefined;

// Check if user is associated with specific domain
// TODO: Should be protected inside GCP if client has a Google Workspace.
const isValidDomainUser = (
  user?: FirebaseUser | null
): user is FirebaseUser =>
  (user && user.email?.includes(GOOGLE_AUTH_DOMAIN)) ?? false;

const signInWithGoogle = () =>
  signInWithRedirect(FirebaseAuth, googleProvider);

const logoutUser = () => FirebaseAuth.signOut();

export {
  isValidDomainUser,
  normalizeFirebaseUser,
  signInWithGoogle,
  logoutUser
};
