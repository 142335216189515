import { Button, Col, Row, Spin } from "antd";
import Form from "antd/lib/form";
import { FormattedMessage } from "react-intl";

import { usePoints } from "./hooks";
import Grades from "./partials/Grades";
import Multipliers from "./partials/Multipliers";

const PointsForm: React.FC = () => {
  const { form, initialValues, onSubmit, isLoading, isSubmitting } =
    usePoints();

  if (isLoading) return <Spin size="large" />;

  return (
    <Form
      {...{ form, initialValues }}
      onFinish={onSubmit}
      autoComplete="off"
      preserve={false}
    >
      <div style={{ display: "flex", gap: 60, flexWrap: "wrap" }}>
        <Grades />
        <Multipliers />
      </div>
      <Button
        loading={isSubmitting}
        style={{ marginTop: 12 }}
        htmlType="submit"
        type="primary"
      >
        <FormattedMessage defaultMessage="Zapisz" />
      </Button>
    </Form>
  );
};

export default PointsForm;
