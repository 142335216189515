import { useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";
import Space from "antd/lib/space";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { ColumnType } from "antd/lib/table";
import { orderBy } from "firebase/firestore";

import {
  useGetTeachersQuery,
  useTeacherDeletion
} from "~api/teachers";
import { Teacher } from "~api/teachers/types";
import Button from "~components/Button";
import { ROUTES } from "~router/consts";
import useConfirmModal from "~hooks/useConfirmModal";

const useTeachers = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const {
    data: dataSource,
    isLoading,
    refetch
  } = useGetTeachersQuery({
    queryConstrains: [orderBy("lastName")],
    select: (snapshot) =>
      (snapshot?.docs ?? []).map((teacher) => ({
        id: teacher.id,
        firstName: teacher.data().firstName,
        lastName: teacher.data().lastName,
        shortcut: teacher.data().shortcut
      })) || []
  });

  const { mutate: deleteTeacher } = useTeacherDeletion({
    onSuccess: () => refetch()
  });

  const showConfirmModal = useConfirmModal();

  const onDeleteClick = useCallback(
    ({ id, firstName, lastName }: WithId<Teacher>) => {
      showConfirmModal({
        onOk: () => deleteTeacher({ id }),
        title: formatMessage(
          {
            defaultMessage:
              "Czy na pewno chcesz usunąć nauczyciela {name}"
          },
          { name: `${firstName} ${lastName}` }
        )
      });
    },
    [formatMessage, deleteTeacher, showConfirmModal]
  );

  const onEditClick = useCallback(
    (id: string) => {
      const path = generatePath(ROUTES.EditTeacher, { id });

      navigate(path);
    },
    [navigate]
  );

  const columns: ColumnType<WithId<Teacher>>[] = useMemo(
    () => [
      {
        title: formatMessage({ defaultMessage: "Imię i nazwisko" }),
        dataIndex: "firstName",
        key: "name",
        sorter: (a, b) => a.lastName.localeCompare(b.lastName),
        render: (firstName, item) => (
          <span>
            {firstName} {item.lastName}
          </span>
        )
      },
      {
        title: formatMessage({ defaultMessage: "Inicjały" }),
        dataIndex: "shortcut",
        key: "shortcut",
        width: 200,
        sorter: (a, b) => a.shortcut.localeCompare(b.shortcut)
      },
      {
        title: formatMessage({ defaultMessage: "Operacje" }),
        key: "action",
        width: 200,
        render: (_, item) => (
          <Space size="middle">
            <Button
              icon={<EditOutlined />}
              onClick={() => onEditClick(item.id)}
            >
              <FormattedMessage
                tagName="span"
                defaultMessage="Edytuj"
              />
            </Button>
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => onDeleteClick(item)}
            >
              <FormattedMessage
                tagName="span"
                defaultMessage="Usuń"
              />
            </Button>
          </Space>
        )
      }
    ],
    [formatMessage, onDeleteClick, onEditClick]
  );

  return {
    loading: isLoading,
    columns,
    dataSource
  };
};

export { useTeachers };
