import { groupBy, uniq } from "lodash";

import { ClassDocument } from "~api/classes/types";
import { Classroom } from "~api/classrooms/types";
import { SubjectDocument } from "~api/subjects/types";
import { Teacher } from "~api/teachers/types";
import { TimetableDocument } from "~api/timetable/types";
import { WorkingDay } from "~hooks/useDays";

import { LibrusCard, LibrusLesson, WithRef } from "../types";

const daysMap: Record<string, WorkingDay> = {
  "10000": WorkingDay.Monday,
  "01000": WorkingDay.Tuesday,
  "00100": WorkingDay.Wednesday,
  "00010": WorkingDay.Thursday,
  "00001": WorkingDay.Friday
};

const prepareClassTimetable = (
  classId: string,
  librusLessons: LibrusLesson[],
  librusCards: LibrusCard[],
  classes: WithRef<ClassDocument>[],
  subjects: WithRef<SubjectDocument>[],
  teachers: WithRef<Teacher>[],
  classrooms: Classroom[],
  groups: Record<string, string>,
  periodsCount: number
): TimetableDocument => {
  const classLessons = librusLessons
    .filter((lesson) => lesson.classids.split(",").includes(classId))
    .map((lesson) => ({
      id: lesson.id,
      cards: librusCards
        .filter((card) => card.lessonid === lesson.id)
        .map((card) => ({
          class: classes.find((c) => c.librusId === classId),
          subject: subjects.find(
            (subject) => subject.librusId === lesson.subjectid
          ),
          teacher: teachers.find(
            (teacher) => teacher.librusId === lesson.teacherids
          ),
          group: uniq(
            lesson.groupids.split(",").map((group) => groups[group])
          ).join("/"),
          classroom: classrooms.find(
            (classroom) =>
              classroom.librusId === card.classroomids.split(",")[0]
          ),
          period: parseInt(card.period, 10) - 1,
          day: daysMap[card.days],
          id: `${classId}_${card.lessonid}_${lesson.subjectid}`
        }))
    }))
    .flatMap((lesson) => lesson.cards)
    .filter((lesson) => !!lesson.day);

  const groupedLessons = groupBy(classLessons, "day");

  return Object.entries(groupedLessons).reduce<TimetableDocument>(
    (prev, [key, lessons]) => {
      const lessonsArray = new Array(periodsCount).fill({
        lessons: []
      });

      lessons.forEach((lesson) => {
        const prevLessons = lessonsArray[lesson.period] || [];

        lessonsArray[lesson.period] = {
          lessons: [
            ...(prevLessons.lessons || []),
            {
              classroom: lesson.classroom?.name ?? null,
              group:
                lesson.group === "Cała klasa"
                  ? null
                  : lesson.group || null,
              subject: lesson.subject?.ref,
              teacher: lesson.teacher?.ref,
              id: lesson.id
            }
          ]
        };
      });

      return {
        ...prev,
        [key]: lessonsArray
      };
    },
    {} as TimetableDocument
  );
};

export default prepareClassTimetable;
