import { FormattedMessage } from "react-intl";
import { Table, Typography } from "antd";
import { useNavigate } from "react-router-dom";

import Button from "~components/Button";
import { ROUTES } from "~router/consts";
import useTableLocale from "~hooks/useTableLocale";

import { useClassProfiles } from "./hooks";

const ClassProfilesPage = () => {
  const { columns, dataSource, loading } = useClassProfiles();
  const navigate = useNavigate();
  const locale = useTableLocale();

  return (
    <>
      <div className="list-header">
        <Typography.Title>
          <FormattedMessage defaultMessage="Profile klas" />
        </Typography.Title>
        <Button onClick={() => navigate(ROUTES.NewClassProfile)}>
          <FormattedMessage defaultMessage="Dodaj profil" />
        </Button>
      </div>
      <Table
        rowKey="id"
        {...{ columns, loading, dataSource, locale }}
      />
    </>
  );
};

export default ClassProfilesPage;
