import { FIRESTORE_COLLECTIONS } from "~setup/consts/firestoreCollections";

import {
  makeFirestoreCollectionMutationHook,
  makeFirestoreDocumentDeletionHook,
  makeFirestoreDocumentHook,
  makeFirestoreDocumentMutationHook,
  makeFirestoreQueryHook
} from "../utils";

import { Teacher } from "./types";

const useGetTeachersQuery = makeFirestoreQueryHook<Teacher>(
  FIRESTORE_COLLECTIONS.Teachers
);

const useGetTeacherQuery = makeFirestoreDocumentHook<Teacher>(
  FIRESTORE_COLLECTIONS.Teachers
);

const useCreateTeacherMutation =
  makeFirestoreCollectionMutationHook<Teacher>(
    FIRESTORE_COLLECTIONS.Teachers
  );

const useUpdateTeacherMutation =
  makeFirestoreDocumentMutationHook<Teacher>(
    FIRESTORE_COLLECTIONS.Teachers
  );

const useTeacherDeletion = makeFirestoreDocumentDeletionHook(
  FIRESTORE_COLLECTIONS.Teachers
);

export {
  useGetTeachersQuery,
  useGetTeacherQuery,
  useUpdateTeacherMutation,
  useCreateTeacherMutation,
  useTeacherDeletion
};
