import {
  QueryType,
  useFirestoreCollectionMutation,
  useFirestoreDocument,
  UseFirestoreHookOptions,
  useFirestoreQuery
} from "@react-query-firebase/firestore";
import {
  collection,
  CollectionReference,
  FirestoreError,
  QuerySnapshot,
  query as firestoreQuery,
  DocumentReference,
  WithFieldValue,
  doc,
  deleteDoc,
  DocumentSnapshot,
  DocumentData,
  SetOptions,
  setDoc,
  PartialWithFieldValue,
  QueryConstraint,
  Query
} from "firebase/firestore";
import {
  useMutation,
  UseMutationOptions,
  UseQueryOptions
} from "react-query";

import { Firestore } from "~setup/lib/firebase";

import {
  DocumentDeletionVariables,
  DocumentUpdateVariables,
  UseFirestoreQueryOptions
} from "./types";

const makeCollectionRef = <T>(
  collectionPath: string
): CollectionReference<T> =>
  collection(Firestore, collectionPath) as CollectionReference<T>;

const makeDefaultQuery = <T>(
  key: string,
  constrains: QueryConstraint[] = []
): Query<T> =>
  firestoreQuery(makeCollectionRef<T>(key), ...constrains);

const makeFirestoreCollectionMutationHook =
  <T>(path: string) =>
  (
    options: UseMutationOptions<
      DocumentReference<T>,
      FirestoreError,
      WithFieldValue<T>
    > = {}
  ) =>
    useFirestoreCollectionMutation<T>(
      makeCollectionRef<T>(path),
      options
    );

const makeFirestoreDocumentMutationHook =
  <T = DocumentData>(key: string) =>
  (
    options?: SetOptions,
    useMutationOptions: UseMutationOptions<
      void,
      FirestoreError,
      DocumentUpdateVariables<T>
    > = {}
  ) => {
    const collectionRef = makeCollectionRef<T>(key);

    return useMutation<
      void,
      FirestoreError,
      DocumentUpdateVariables<T & { id: string }>
    >(
      ({ id, ...data }) => {
        const docRef = doc(collectionRef, id);

        if (options) {
          return setDoc<T>(
            docRef,
            data as unknown as PartialWithFieldValue<T>,
            options
          );
        }

        return setDoc<T>(
          docRef,
          data as unknown as WithFieldValue<T>
        );
      },
      { ...useMutationOptions }
    );
  };

const makeFirestoreDocumentDeletionHook =
  <
    TVariables extends DocumentDeletionVariables = DocumentDeletionVariables
  >(
    path: string
  ) =>
  (
    options: UseMutationOptions<void, FirestoreError, TVariables> = {}
  ) => {
    const collectionRef = makeCollectionRef(path);

    return useMutation<void, FirestoreError, TVariables>(
      ({ id }) => deleteDoc(doc(collectionRef, id)),
      options
    );
  };

const makeFirestoreQueryHook =
  <T, R = QuerySnapshot<T>>(path: string, query?: QueryType<T>) =>
  <TData = R>(
    options: UseFirestoreQueryOptions<
      QuerySnapshot<T>,
      FirestoreError,
      TData
    > = {},
    queryOptions: UseFirestoreHookOptions = {
      includeMetadataChanges: true,
      subscribe: true
    }
  ) => {
    const namedQuery =
      query ??
      makeDefaultQuery<T>(path, options.queryConstrains ?? []);

    return useFirestoreQuery<T, TData>(
      options.queryKey || path,
      namedQuery,
      queryOptions,
      options
    );
  };

const makeFirestoreDocumentHook =
  <T, R = DocumentSnapshot<T>>(
    key: string,
    ref: CollectionReference<T> = makeCollectionRef<T>(key)
  ) =>
  <TData = R>(
    path: string | undefined,
    options: UseQueryOptions<
      DocumentSnapshot<T>,
      FirestoreError,
      TData
    > = {},
    queryOptions: UseFirestoreHookOptions = {
      includeMetadataChanges: true,
      subscribe: true
    }
  ) => {
    const docRef = doc(ref, path);

    return useFirestoreDocument<T, TData>(
      [key, path],
      docRef,
      queryOptions,
      options
    );
  };

const makeFirestoreDocumentHookWithKey =
  <T, R = DocumentSnapshot<T>>(
    key: string,
    path: string,
    ref: CollectionReference<T> = makeCollectionRef<T>(key)
  ) =>
  <TData = R>(
    options: UseQueryOptions<
      DocumentSnapshot<T>,
      FirestoreError,
      TData
    > = {},
    queryOptions: UseFirestoreHookOptions = {
      includeMetadataChanges: true,
      subscribe: true
    }
  ) => {
    const docRef = doc(ref, path);

    return useFirestoreDocument<T, TData>(
      [key, path],
      docRef,
      queryOptions,
      options
    );
  };

export {
  makeCollectionRef,
  makeFirestoreQueryHook,
  makeFirestoreDocumentHook,
  makeFirestoreDocumentHookWithKey,
  makeFirestoreDocumentDeletionHook,
  makeFirestoreCollectionMutationHook,
  makeFirestoreDocumentMutationHook
};
