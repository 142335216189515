import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import moment from "moment";
import notification from "antd/lib/notification";

import { Event } from "~api/events/types";
import {
  useGetEventQuery,
  useUpdateEventMutation
} from "~api/events";
import { queryClient } from "~providers/react-query/consts";
import { FIRESTORE_COLLECTIONS } from "~setup/consts/firestoreCollections";

import { EventFormValues } from "../partials/form/types";
import { Timestamp } from "firebase/firestore";

const useEditEvent = () => {
  const { id } = useParams();
  const { formatMessage } = useIntl();
  const { isLoading: eventLoading, data: initialValues = {} } =
    useGetEventQuery<Partial<EventFormValues>>(id, {
      enabled: !!id,
      select: (snapshot) => ({
        title: snapshot.data()?.title || "",
        description: snapshot.data()?.description || "",
        startDate: moment(snapshot.data()?.date.start.toDate()),
        endDate: snapshot.data()?.date.end
          ? moment(snapshot.data()?.date.end?.toDate())
          : undefined,
        minAge: snapshot.data()?.age.min || undefined,
        maxAge: snapshot.data()?.age.max || undefined,
        classesIds: snapshot.data()?.classes.map((c) => c.id),
        categoryId: snapshot.data()?.category.id || undefined,
        subcategoryId: snapshot.data()?.subcategory?.id
      })
    });

  const { mutateAsync, isLoading } = useUpdateEventMutation({
    merge: true
  });

  const onSubmit = useCallback(
    async (data: Event) => {
      if (!id) return;

      await mutateAsync({
        id,
        ...data,
        updatedAt: Timestamp.fromDate(new Date())
      });

      await queryClient.invalidateQueries(
        FIRESTORE_COLLECTIONS.Events
      );

      notification.success({
        message: formatMessage({
          defaultMessage: "Dane zostały zaktualizowane"
        })
      });
    },
    [formatMessage, id, mutateAsync]
  );

  return { onSubmit, isLoading, eventLoading, initialValues };
};

export { useEditEvent };
