import { FIRESTORE_COLLECTIONS } from "~setup/consts/firestoreCollections";

import {
  makeFirestoreDocumentHook,
  makeFirestoreDocumentMutationHook
} from "../utils";

import { LessonTimes } from "./types";

const useGetLessonTimesQuery = makeFirestoreDocumentHook<LessonTimes>(
  FIRESTORE_COLLECTIONS.LessonTimes
);

const useLessonTimesMutation =
  makeFirestoreDocumentMutationHook<LessonTimes>(
    FIRESTORE_COLLECTIONS.LessonTimes
  );

export { useGetLessonTimesQuery, useLessonTimesMutation };
