import Upload, { RcFile } from "antd/lib/upload";
import { XMLParser } from "fast-xml-parser";
import {
  UploadChangeParam,
  UploadFile
} from "antd/lib/upload/interface";
import { useCallback, useState } from "react";
import { Modal } from "antd";
import { useIntl } from "react-intl";

import importData from "~utils/import-data";

import { readFile } from "./utils";

const parser = new XMLParser({
  ignoreAttributes: false,
  attributeNamePrefix: ""
});

const useImportDataField = () => {
  const { formatMessage } = useIntl();
  const [isImporting, setIsImporting] = useState(false);

  const beforeUpload = useCallback((file: RcFile) => {
    const isXML = file.type === "text/xml";

    if (!isXML) return Upload.LIST_IGNORE;

    return false;
  }, []);

  const handleImport = useCallback(
    async (file: File) => {
      try {
        setIsImporting(true);

        const res = await readFile(file);

        const librusData = parser.parse(res);

        await importData(librusData);

        Modal.success({
          title: formatMessage({
            defaultMessage:
              "Pomyślnie zaimportowano dane z systemu Librus. Sprawdź, czy nie występują żadne duplikaty."
          })
        });
      } finally {
        setIsImporting(false);
      }
    },
    [formatMessage]
  );

  const onChange = useCallback(
    ({ file }: UploadChangeParam<UploadFile<unknown>>) => {
      Modal.confirm({
        title: formatMessage({
          defaultMessage: "Import danych z systemu Librus"
        }),
        content: formatMessage({
          defaultMessage:
            "Upewnij się, że wybrałeś poprawny plik .XML z danymi wyeksportowanymi z systemu Librus. Po zatwierdzeniu operacji, nie będzie można tego przerwać, a część aktualnych danych zostanie usunięta. Istnieje ryzyko, że Librus wygenerował nowe identyfikatory, przez co mogą wystąpić duplikaty niektórych danych. Czy chcesz kontynuować?"
        }),
        okText: formatMessage({ defaultMessage: "Importuj" }),
        onOk: () => handleImport(file as unknown as File),
        cancelText: formatMessage({ defaultMessage: "Anuluj" })
      });
    },
    [formatMessage, handleImport]
  );

  return { isImporting, beforeUpload, onChange };
};

export { useImportDataField };
