import { useCallback } from "react";
import { useIntl } from "react-intl";
import Modal, { ModalFuncProps } from "antd/lib/modal";

const useConfirmModal = (options: ModalFuncProps = {}) => {
  const { formatMessage } = useIntl();

  return useCallback(
    (opts: ModalFuncProps = {}) =>
      Modal.confirm({
        closable: true,
        centered: true,
        title: formatMessage({
          defaultMessage: "Czy na pewno chcesz wykonać tę akcję??"
        }),
        okText: formatMessage({ defaultMessage: "Tak" }),
        cancelText: formatMessage({ defaultMessage: "Nie" }),
        ...options,
        ...opts
      }),
    [formatMessage, options]
  );
};

export default useConfirmModal;
