import Form from "antd/lib/form";
import Select from "antd/lib/select";
import { useIntl } from "react-intl";

import useCommonRules from "~hooks/useCommonRules";

import { SubjectsFormProps } from "./types";

const SubjectsForm: React.FC<SubjectsFormProps> = ({
  name,
  label,
  options = []
}) => {
  const { formatMessage } = useIntl();
  const { required } = useCommonRules();

  return (
    <Form.Item
      style={{ minWidth: 300, width: "100%", maxWidth: 500 }}
      {...{ name, label }}
      rules={[required]}
    >
      <Select
        mode="tags"
        style={{ width: "100%" }}
        placeholder={formatMessage({
          defaultMessage: "Wpisz lub wybierz przedmioty"
        })}
      >
        {options.map((option) => (
          <Select.Option key={option}>{option}</Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default SubjectsForm;
