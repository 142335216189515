import { useMemo } from "react";
import { useIntl } from "react-intl";

const useMultipliers = () => {
  const { formatMessage } = useIntl();

  return useMemo(
    () => [
      {
        label: formatMessage({ defaultMessage: "Matematyka" }),
        key: "math",
        default: 0.35
      },
      {
        label: formatMessage({
          defaultMessage: "Język polski"
        }),
        key: "polish",
        default: 0.35
      },
      {
        label: formatMessage({ defaultMessage: "Język obcy" }),
        key: "foreignLanguage",
        default: 0.3
      }
    ],
    [formatMessage]
  );
};

export { useMultipliers };
