import { ArrowRightOutlined, SaveOutlined } from "@ant-design/icons";
import MinusCircleOutlined from "@ant-design/icons/lib/icons/MinusCircleOutlined";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import { TimePicker, Typography } from "antd";
import Form from "antd/lib/form";
import Space from "antd/lib/space";
import { FormattedMessage, useIntl } from "react-intl";

import Button from "~components/Button";
import useCommonRules from "~hooks/useCommonRules";
import { DEFAULT_TIME_FORMAT } from "~utils/date-utils";

import { LessonTimesProps } from "./types";

const LessonTimesForm: React.FC<LessonTimesProps> = ({
  isLoading,
  initialValues = { lessonTimes: [[]] },
  onSubmit
}) => {
  const { formatMessage } = useIntl();
  const { required } = useCommonRules();

  return (
    <Form
      {...{ initialValues }}
      onFinish={onSubmit}
      name="lesson-times-form"
    >
      <Form.List name="lessonTimes">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, idx) => (
              <Space
                key={key}
                align="center"
                style={{ display: "flex", marginBottom: 24 }}
              >
                <Typography.Title
                  level={4}
                  style={{ marginBottom: 0 }}
                >
                  {idx + 1}
                </Typography.Title>
                <Form.Item
                  {...restField}
                  style={{ marginBottom: 0 }}
                  name={[name]}
                  rules={[required]}
                >
                  <TimePicker.RangePicker
                    separator={<ArrowRightOutlined />}
                    format={DEFAULT_TIME_FORMAT}
                    placeholder={[
                      formatMessage({
                        defaultMessage: "Od"
                      }),
                      formatMessage({
                        defaultMessage: "Do"
                      })
                    ]}
                  />
                </Form.Item>
                {fields.length > 1 && (
                  <MinusCircleOutlined onClick={() => remove(name)} />
                )}
              </Space>
            ))}
            <Form.Item>
              <Button
                onClick={() => add()}
                type="primary"
                ghost={false}
                loading={isLoading}
                disabled={isLoading}
                icon={<PlusOutlined />}
              >
                <FormattedMessage
                  tagName="span"
                  defaultMessage="Dodaj godziny"
                />
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      <Button
        htmlType="submit"
        type="primary"
        loading={isLoading}
        disabled={isLoading}
        icon={<SaveOutlined />}
        style={{ marginTop: 48 }}
      >
        <FormattedMessage tagName="span" defaultMessage="Zapisz" />
      </Button>
    </Form>
  );
};

export default LessonTimesForm;
