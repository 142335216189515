import { FIRESTORE_COLLECTIONS } from "~setup/consts/firestoreCollections";

import {
  makeFirestoreCollectionMutationHook,
  makeFirestoreDocumentDeletionHook,
  makeFirestoreDocumentHook,
  makeFirestoreDocumentMutationHook,
  makeFirestoreQueryHook
} from "../utils";

import { ClassDocument } from "./types";

const useGetClasses = makeFirestoreQueryHook<ClassDocument>(
  FIRESTORE_COLLECTIONS.Classes
);

const useGetClassQuery = makeFirestoreDocumentHook<ClassDocument>(
  FIRESTORE_COLLECTIONS.Classes
);

const useCreateClassMutation =
  makeFirestoreCollectionMutationHook<ClassDocument>(
    FIRESTORE_COLLECTIONS.Classes
  );

const useUpdateClassMutation =
  makeFirestoreDocumentMutationHook<ClassDocument>(
    FIRESTORE_COLLECTIONS.Classes
  );

const useClassDeletion = makeFirestoreDocumentDeletionHook(
  FIRESTORE_COLLECTIONS.Classes
);

export {
  useGetClasses,
  useGetClassQuery,
  useCreateClassMutation,
  useUpdateClassMutation,
  useClassDeletion
};
