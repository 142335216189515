import { Navigate, useParams } from "react-router-dom";

import { ROUTES } from "~router/consts";
import TimetableProvider from "~providers/timetable";

import ClassTimetable from ".";

const ClassTimetableWrapper = () => {
  const { id: classId } = useParams();

  if (!classId) {
    return <Navigate to={ROUTES.Root} />;
  }

  return (
    <TimetableProvider value={{ classId, autosave: true }}>
      <ClassTimetable />
    </TimetableProvider>
  );
};

export default ClassTimetableWrapper;
