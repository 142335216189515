import { FIRESTORE_COLLECTIONS } from "~setup/consts/firestoreCollections";

import {
  makeFirestoreDocumentDeletionHook,
  makeFirestoreDocumentHook,
  makeFirestoreQueryHook
} from "../utils";

import { SaleOffer } from "./types";

const useGetSaleOffersQuery = makeFirestoreQueryHook<SaleOffer>(
  FIRESTORE_COLLECTIONS.Marketplace
);

const useGetSaleOfferQuery = makeFirestoreDocumentHook<SaleOffer>(
  FIRESTORE_COLLECTIONS.Marketplace
);

const useSaleOfferDeletion = makeFirestoreDocumentDeletionHook(
  FIRESTORE_COLLECTIONS.Marketplace
);

export {
  useGetSaleOffersQuery,
  useGetSaleOfferQuery,
  useSaleOfferDeletion
};
