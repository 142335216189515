export const ROUTES = {
  Root: "/",
  Login: "/auth/login",
  Administrators: "/administrators",
  Teachers: "/teachers",
  NewTeacher: "/teachers/new",
  EditTeacher: "/teachers/:id/edit",
  Classes: "/classes",
  NewClass: "/classes/new",
  EditClass: "/classes/:id/edit",
  ClassTimetable: "/classes/:id/timetable",
  Recruitment: "/recruitment",
  ClassProfiles: "/recruitment/class-profiles",
  NewClassProfile: "/recruitment/class-profiles/new",
  EditClassProfile: "/recruitment/class-profiles/:id/edit",
  Subjects: "/subjects",
  NewSubject: "/subjects/new",
  EditSubject: "/subjects/:id/edit",
  LessonTimes: "/lesson-times",
  Events: "/events",
  ArchiveEvents: "/events/archive",
  NewEvent: "/events/new",
  EditEvent: "/events/:id/edit",
  SaleOffers: "/sale-offers",
  SaleOfferDetails: "/sale-offers/:id"
};
