import {
  BellOutlined,
  BookOutlined,
  CalendarOutlined,
  DashboardOutlined,
  HolderOutlined,
  LockOutlined,
  DollarCircleOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SearchOutlined,
  TeamOutlined
} from "@ant-design/icons";
import { useIntl } from "react-intl";
import { useCallback, useState } from "react";
import { useLocation } from "react-router-dom";

import { ROUTES } from "~router/consts";
import { breakpoints } from "~setup/consts/breakpoints";

import { NavigationItem } from "./types";

const useNavigationItems = (): NavigationItem[] => {
  const { formatMessage } = useIntl();

  return [
    {
      href: ROUTES.Root,
      title: formatMessage({ defaultMessage: "Dashboard" }),
      icon: DashboardOutlined
    },
    {
      href: ROUTES.Administrators,
      title: formatMessage({ defaultMessage: "Administratorzy" }),
      icon: LockOutlined
    },
    {
      href: ROUTES.Teachers,
      title: formatMessage({ defaultMessage: "Nauczyciele" }),
      icon: TeamOutlined
    },
    {
      href: ROUTES.Subjects,
      title: formatMessage({ defaultMessage: "Przedmioty" }),
      icon: BookOutlined
    },
    {
      href: ROUTES.Classes,
      title: formatMessage({ defaultMessage: "Klasy" }),
      icon: HolderOutlined
    },
    {
      href: ROUTES.Recruitment,
      title: formatMessage({ defaultMessage: "Rekrutacja" }),
      icon: SearchOutlined,
      children: [
        {
          href: ROUTES.Recruitment,
          title: formatMessage({ defaultMessage: "Punktacja" })
        },
        {
          href: ROUTES.ClassProfiles,
          title: formatMessage({ defaultMessage: "Profile klas" })
        }
      ]
    },
    {
      href: ROUTES.Events,
      title: formatMessage({ defaultMessage: "Wydarzenia" }),
      icon: CalendarOutlined,
      children: [
        {
          href: ROUTES.Events,
          title: formatMessage({ defaultMessage: "Aktualne" })
        },
        {
          href: ROUTES.ArchiveEvents,
          title: formatMessage({ defaultMessage: "Przeszłe" })
        }
      ]
    },
    {
      href: ROUTES.LessonTimes,
      title: formatMessage({ defaultMessage: "Godziny lekcyjne" }),
      icon: BellOutlined
    },
    {
      href: ROUTES.SaleOffers,
      title: formatMessage({ defaultMessage: "Giełda" }),
      icon: DollarCircleOutlined
    }
  ];
};

const useLayout = () => {
  const [isCollapsed, setCollapsed] = useState(
    !window.matchMedia(`(min-width: ${breakpoints.md}px)`).matches
  );

  const { pathname } = useLocation();
  const openKeys = pathname.split("/").splice(0, 2).join("/");

  const toggle = useCallback((collapsed?: boolean) => {
    setCollapsed((prev) =>
      collapsed === undefined ? !prev : collapsed
    );
  }, []);

  const ToggleIcon = isCollapsed
    ? MenuUnfoldOutlined
    : MenuFoldOutlined;

  return {
    isCollapsed,
    openKeys: [`${openKeys}_parent`],
    pathname,
    toggle,
    ToggleIcon
  };
};

export { useNavigationItems, useLayout };
