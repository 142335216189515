import { FormattedMessage } from "react-intl";
import { Table, Typography } from "antd";

import MarketplaceToggler from "~components/MarketplaceToggler";
import useTableLocale from "~hooks/useTableLocale";

import { useSaleOffers } from "./hooks";

const SaleOffersListPage = () => {
  const { columns, dataSource, loading } = useSaleOffers();
  const locale = useTableLocale();

  return (
    <>
      <div className="list-header">
        <Typography.Title>
          <FormattedMessage defaultMessage="Giełda podręczników" />
        </Typography.Title>
        <MarketplaceToggler />
      </div>
      <Table
        rowKey="id"
        {...{ columns, loading, dataSource, locale }}
      />
    </>
  );
};

export default SaleOffersListPage;
