import { Spin } from "antd";
import Title from "antd/lib/typography/Title";
import Radio from "antd/lib/radio";
import { FormattedMessage } from "react-intl";
import SyncOutlined from "@ant-design/icons/lib/icons/SyncOutlined";

import { useGetClassQuery } from "~api/classes";
import { useTimetable } from "~providers/timetable/context";
import useWorkingDays, { WorkingDay } from "~hooks/useDays";

import TimeSlot from "./partials/TimeSlot";

const ClassTimetable = () => {
  const {
    classId,
    selectedDay,
    isSyncing,
    isLoading,
    lessonTimes,
    onChangeDay
  } = useTimetable();

  const days = useWorkingDays();

  const { data: classData } = useGetClassQuery(classId, {
    enabled: !!classId,
    select: (snapshot) => ({ name: snapshot.data()?.name })
  });

  if (isLoading) return <Spin size="large" />;

  return (
    <>
      <div className="list-header">
        <Title>
          <FormattedMessage
            defaultMessage="Plan lekcji klasy {className}"
            values={{ className: classData?.name }}
          />
        </Title>
        {isSyncing && <SyncOutlined spin style={{ fontSize: 24 }} />}
      </div>
      <Radio.Group
        defaultValue={WorkingDay.Monday}
        value={selectedDay}
        onChange={(event) => onChangeDay(event.target.value)}
        buttonStyle="solid"
        style={{ margin: "16px 0" }}
      >
        {days.map((day) => (
          <Radio.Button key={day.key} value={day.key}>
            {day.label}
          </Radio.Button>
        ))}
      </Radio.Group>
      <div className="timetable-wrapper">
        {lessonTimes.map(({ from, to }, rowIndex) => (
          <div key={`${from}_${to}`} className="timetable-row">
            <span className="timetable-time">
              {from} - {to}
            </span>
            <TimeSlot path={`${selectedDay}[${rowIndex}].lessons`} />
          </div>
        ))}
      </div>
    </>
  );
};

export default ClassTimetable;
