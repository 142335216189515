import EventForm from "../partials/form";

import { useNewEvent } from "./hooks";

const NewEventPage: React.FC = () => {
  const { onSubmit, isLoading } = useNewEvent();

  return <EventForm {...{ onSubmit, isLoading }} />;
};

export default NewEventPage;
