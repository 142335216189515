import { useForm, useWatch } from "antd/lib/form/Form";
import { Timestamp } from "firebase/firestore";
import { omit } from "lodash";
import { useCallback, useMemo } from "react";

import { useGetClasses } from "~api/classes";
import { Class } from "~api/classes/types";
import { useGetEventCategoriesQuery } from "~api/constants";
import { EventCategory } from "~api/constants/types";
import { Event } from "~api/events/types";

import { EventFormValues } from "./types";

const useEventForm = (
  onSubmit?: (data: Event) => void,
  editMode = false
) => {
  const [form] = useForm<EventFormValues>();
  const categoryId = useWatch<string>("categoryId", form);

  const { data: categories = [], isLoading: categoriesLoading } =
    useGetEventCategoriesQuery<EventCategory[]>({
      select: (snapshot) => snapshot.data()?.categories ?? []
    });

  const { data: classes = [], isLoading: classesLoading } =
    useGetClasses<WithId<Class>[]>({
      select: (snapshot) =>
        snapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().name
        }))
    });

  const selectedCategory = useMemo(
    () => categories.find((category) => category.id === categoryId),
    [categories, categoryId]
  );

  const handleSubmit = useCallback(
    async (data: EventFormValues) => {
      const event: Event = {
        title: data.title,
        description: data.description,
        age: {
          min: data.minAge || null,
          max: data.maxAge || null
        },
        date: {
          start: Timestamp.fromDate(data.startDate.toDate()),
          end: data.endDate
            ? Timestamp.fromDate(data.endDate?.toDate())
            : null
        },
        category: omit(selectedCategory, "subcategories"),
        subcategory:
          selectedCategory?.subcategories.find(
            (subcategory) => subcategory.id === data.subcategoryId
          ) || null,
        classes: classes.filter(
          (c) => (data.classesIds ?? []).includes(c.id) ?? []
        ),
        createdAt: Timestamp.fromDate(new Date()),
        updatedAt: Timestamp.fromDate(new Date())
      };

      await onSubmit?.(event);

      if (!editMode) {
        form.resetFields();
      }
    },
    [classes, editMode, form, onSubmit, selectedCategory]
  );

  return useMemo(
    () => ({
      form,
      categories,
      categoriesLoading,
      classes,
      classesLoading,
      selectedCategory,
      handleSubmit
    }),
    [
      form,
      categories,
      categoriesLoading,
      classes,
      classesLoading,
      selectedCategory,
      handleSubmit
    ]
  );
};

export { useEventForm };
