import React from "react";
import Spin from "antd/lib/spin";

import SubjectForm from "../partials/form";

import { useEditSubject } from "./hooks";

const EditSubjectPage: React.FC = () => {
  const { isSubjectLoading, subjectData, isLoading, onUpdate } =
    useEditSubject();

  if (isSubjectLoading) return <Spin size="large" />;

  return (
    <SubjectForm
      editMode
      onSubmit={onUpdate}
      initialValues={subjectData}
      {...{ isLoading }}
    />
  );
};

export default EditSubjectPage;
