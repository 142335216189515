import { useCallback } from "react";
import {
  useGetMarketplaceQuery,
  useUpdateMarketplaceMutation
} from "~api/constants";
import { CONSTANTS_KEYS } from "~api/constants/consts";

const useMarketplaceToggler = () => {
  const { data: isEnabled = false, isLoading } =
    useGetMarketplaceQuery({
      staleTime: 0,
      cacheTime: 0,
      select: (snapshot) => snapshot.data()?.enabled ?? false
    });

  const { mutate, isLoading: isSubmitting } =
    useUpdateMarketplaceMutation({ merge: true });

  const onChange = useCallback(
    (enabled: boolean) => {
      mutate({
        id: CONSTANTS_KEYS.Marketplace,
        enabled
      });
    },
    [mutate]
  );

  return {
    isEnabled,
    isLoading: isLoading || isSubmitting,
    onChange
  };
};

export { useMarketplaceToggler };
