import { createContext, useContext } from "react";

import noop from "~utils/noop";

import { AuthState } from "./types";

const AuthContext = createContext<AuthState>({
  user: undefined,
  isLoggedIn: false,
  isAuthLoading: false,
  signOut: noop
});

const useAuth = () => useContext(AuthContext);

export { AuthContext, useAuth };
