import Form from "antd/lib/form";
import Select from "antd/lib/select";
import React from "react";
import { FormattedMessage } from "react-intl";
import FormItem from "antd/lib/form/FormItem";
import { DeleteOutlined } from "@ant-design/icons";

import Button from "~components/Button";

import { SelectFieldArrayProps } from "./types";

const SelectFieldArray: React.FC<SelectFieldArrayProps> = ({
  label,
  name,
  listName,
  mode,
  arrayRules = [],
  rules = [],
  options,
  loading,
  selectPlaceholder,
  addBtnLabel,
  min = 0
}) => (
  <FormItem
    {...{ label }}
    name={listName}
    rules={arrayRules}
    className="select-field-array-wrapper"
  >
    <Form.List
      initialValue={Array(min).fill(undefined)}
      name={listName}
    >
      {(fields, { add, remove }) => (
        <>
          {fields.map((field, id) => (
            <div key={field.key} className="single-field-wrapper">
              <Form.Item
                {...field}
                name={name ? [field.name, name] : field.name}
                {...{ rules }}
                className="w-100"
              >
                <Select
                  {...{ loading, mode, options }}
                  placeholder={selectPlaceholder}
                  disabled={loading}
                />
              </Form.Item>
              {fields.length > min && (
                <Button
                  onClick={() => remove(id)}
                  className="ml-auto"
                  danger
                  ghost={false}
                  shape="circle"
                  icon={<DeleteOutlined />}
                />
              )}
            </div>
          ))}
          <Button onClick={() => add()}>
            {addBtnLabel || (
              <FormattedMessage defaultMessage="Dodaj" />
            )}
          </Button>
        </>
      )}
    </Form.List>
  </FormItem>
);

export default SelectFieldArray;
