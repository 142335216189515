import React from "react";

import SubjectForm from "../partials/form";

import { useNewSubject } from "./hooks";

const NewSubjectPage: React.FC = () => {
  const { isLoading, onSubmit } = useNewSubject();

  return <SubjectForm {...{ onSubmit, isLoading }} />;
};

export default NewSubjectPage;
