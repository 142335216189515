import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import {
  getFirestore,
  enableIndexedDbPersistence,
  connectFirestoreEmulator,
  clearIndexedDbPersistence
} from "firebase/firestore";

import {
  FIREBASE_API_KEY,
  FIREBASE_APP_ID,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
  FIREBASE_USE_CACHE,
  FIREBASE_USE_EMULATORS,
  NODE_ENV
} from "~setup/consts/environment";

const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: FIREBASE_APP_ID
};

const Firebase = initializeApp(firebaseConfig);
const Firestore = getFirestore(Firebase);
const FirebaseAuth = getAuth(Firebase);

if (FIREBASE_USE_EMULATORS && NODE_ENV !== "production") {
  clearIndexedDbPersistence(Firestore);
  connectFirestoreEmulator(Firestore, "localhost", 8080);
  connectAuthEmulator(FirebaseAuth, "http://localhost:9099");
}

if (NODE_ENV === "production" || FIREBASE_USE_CACHE) {
  enableIndexedDbPersistence(Firestore);
}

export default Firebase;
export { Firestore, FirebaseAuth };
