import { useCallback, useEffect, useMemo, useState } from "react";
import Space from "antd/lib/space";
import { FormattedMessage, useIntl } from "react-intl";
import { ColumnType } from "antd/lib/table";
import {
  DeleteOutlined,
  EditOutlined,
  FieldTimeOutlined
} from "@ant-design/icons";
import { generatePath, useNavigate } from "react-router-dom";
import { orderBy } from "firebase/firestore";

import { useClassDeletion, useGetClasses } from "~api/classes";
import Button from "~components/Button";
import { Class } from "~api/classes/types";
import { Teacher } from "~api/teachers/types";
import useConfirmModal from "~hooks/useConfirmModal";
import { ROUTES } from "~router/consts";

import { transformClasses } from "../utils";

const useClasses = () => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const [isLoading, setIsLoading] = useState(true);
  const { mutate } = useClassDeletion();
  const [classes, setClasses] = useState<WithId<Class>[]>([]);

  const showConfirmModal = useConfirmModal();

  const { data, isLoading: classesLoading } = useGetClasses({
    queryConstrains: [orderBy("name")]
  });

  const onEditClick = useCallback(
    (id: string) => {
      const path = generatePath(ROUTES.EditClass, { id });

      navigate(path);
    },
    [navigate]
  );

  const onDeleteClick = useCallback(
    ({ id, name }: WithId<Class>) => {
      showConfirmModal({
        onOk: () => mutate({ id }),
        title: formatMessage(
          {
            defaultMessage: "Czy na pewno chcesz usunąć klasę {name}?"
          },
          { name }
        )
      });
    },
    [formatMessage, mutate, showConfirmModal]
  );

  useEffect(() => {
    if (!data?.docs.length) {
      setIsLoading(false);

      return;
    }

    const transform = async () => {
      const newClasses = await transformClasses(data);

      setIsLoading(false);
      setClasses(newClasses);
    };

    transform();
  }, [data]);

  const columns: ColumnType<WithId<Class>>[] = useMemo(
    () => [
      {
        title: formatMessage({ defaultMessage: "Klasa" }),
        dataIndex: "name",
        key: "name",
        width: 150,
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (name) => <b>{name}</b>
      },
      {
        title: formatMessage({ defaultMessage: "Wychowawca" }),
        dataIndex: "supervisor",
        key: "supervisor",
        sorter: (a, b) =>
          (a.supervisor?.lastName || "").localeCompare(
            b.supervisor?.lastName || ""
          ),
        render: (supervisor?: Teacher) =>
          supervisor ? (
            <span>
              {supervisor.firstName} {supervisor.lastName}
            </span>
          ) : (
            "-"
          )
      },
      {
        title: formatMessage({ defaultMessage: "Operacje" }),
        key: "action",
        width: 200,
        render: (_, item) => (
          <Space size="middle">
            <Button
              icon={<FieldTimeOutlined />}
              onClick={() =>
                navigate(
                  generatePath(ROUTES.ClassTimetable, { id: item.id })
                )
              }
            >
              <FormattedMessage
                tagName="span"
                defaultMessage="Plan lekcji"
              />
            </Button>
            <Button
              icon={<EditOutlined />}
              onClick={() => onEditClick(item.id)}
            >
              <FormattedMessage
                tagName="span"
                defaultMessage="Edytuj"
              />
            </Button>
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => onDeleteClick(item)}
            >
              <FormattedMessage
                tagName="span"
                defaultMessage="Usuń"
              />
            </Button>
          </Space>
        )
      }
    ],
    [formatMessage, navigate, onDeleteClick, onEditClick]
  );

  return {
    loading: isLoading || classesLoading,
    columns,
    dataSource: classes
  };
};

export { useClasses };
