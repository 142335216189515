import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import notification from "antd/lib/notification";
import { doc } from "firebase/firestore";

import { queryClient } from "~providers/react-query/consts";
import { FIRESTORE_COLLECTIONS } from "~setup/consts/firestoreCollections";
import {
  useGetClassQuery,
  useUpdateClassMutation
} from "~api/classes";
import { Firestore } from "~setup/lib/firebase";

import { ClassFormValues } from "../partials/form/types";

const useEditClass = () => {
  const { id } = useParams();
  const { formatMessage } = useIntl();
  const { isLoading: isClassLoading, data } = useGetClassQuery(id, {
    enabled: !!id
  });

  const { mutateAsync, isLoading } = useUpdateClassMutation();

  const onUpdate = useCallback(
    async (formData: ClassFormValues) => {
      if (!id) return;

      await mutateAsync({
        id,
        name: formData.name,
        supervisor: doc(Firestore, formData.supervisorRef)
      });

      await queryClient.invalidateQueries(
        FIRESTORE_COLLECTIONS.Classes
      );

      notification.success({
        message: formatMessage({
          defaultMessage: "Dane zostały zaktualizowane"
        })
      });
    },
    [formatMessage, id, mutateAsync]
  );

  const classData: ClassFormValues = useMemo(
    () => ({
      name: data?.data()?.name ?? "",
      supervisorRef: data?.data()?.supervisor?.path ?? ""
    }),
    [data]
  );

  return { isClassLoading, classData, onUpdate, isLoading };
};

export { useEditClass };
