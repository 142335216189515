import { Form, Input } from "antd";
import { useForm } from "antd/lib/form/Form";
import Modal from "antd/lib/modal/Modal";
import { useIntl } from "react-intl";

import noop from "~utils/noop";
import useCommonRules from "~hooks/useCommonRules";

import { useAdministratorForm } from "./hooks";
import {
  AdministratorFormModalProps,
  AdministratorFormValues
} from "./types";

const AdministratorFormModal: React.FC<
  AdministratorFormModalProps
> = ({ isVisible, onCancel = noop, onSuccess = noop }) => {
  const [form] = useForm<AdministratorFormValues>();
  const { formatMessage } = useIntl();
  const { required, email } = useCommonRules();
  const { onSubmit, isLoading } = useAdministratorForm({ onSuccess });

  return (
    <Modal
      centered
      title={formatMessage({
        defaultMessage: "Tworzenie nowego administratora"
      })}
      visible={isVisible}
      destroyOnClose
      onCancel={onCancel}
      closable={!isLoading}
      cancelText={formatMessage({
        defaultMessage: "Anuluj"
      })}
      okText={formatMessage({
        defaultMessage: "Dodaj administratora"
      })}
      onOk={form.submit}
      cancelButtonProps={{ disabled: isLoading }}
      okButtonProps={{ loading: isLoading }}
    >
      <Form
        {...{ form }}
        name="administrator-form"
        onFinish={onSubmit}
        autoComplete="off"
        preserve={false}
      >
        <Form.Item
          label={formatMessage({ defaultMessage: "Adres e-mail" })}
          name="email"
          rules={[required, email]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AdministratorFormModal;
