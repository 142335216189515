import { addDoc, getDocs, query } from "firebase/firestore";

import { Teacher } from "~api/teachers/types";
import { makeCollectionRef } from "~api/utils";
import { FIRESTORE_COLLECTIONS } from "~setup/consts/firestoreCollections";

import { LibrusTeacher, WithRef } from "../types";

const importTeachers = async (
  librusTeachers: LibrusTeacher[]
): Promise<WithRef<Teacher>[]> => {
  const ref = makeCollectionRef<Teacher>(
    FIRESTORE_COLLECTIONS.Teachers
  );

  const { docs } = await getDocs(query(ref));

  const existingTeachers =
    docs.map((doc) => ({
      ...doc.data(),
      ref: doc.ref
    })) || [];

  const newTeachers = await librusTeachers.reduce(
    async (prev, current) => {
      const awaitedPrev = await prev;

      if (
        existingTeachers.findIndex(
          (teacher) => teacher.librusId === current.id
        ) >= 0
      ) {
        return awaitedPrev;
      }

      const newTeacherData = {
        firstName: current.firstname,
        lastName: current.lastname,
        shortcut: current.short,
        librusId: current.id
      };

      const newTeacherReference = await addDoc(ref, newTeacherData);

      return [
        ...awaitedPrev,
        {
          ...newTeacherData,
          ref: newTeacherReference
        }
      ];
    },
    Promise.resolve<WithRef<Teacher>[]>([])
  );

  return [...existingTeachers, ...newTeachers];
};

export default importTeachers;
