import Select from "antd/lib/select";
import Input from "antd/lib/input/Input";
import FormItem from "antd/lib/form/FormItem";
import { FormattedMessage } from "react-intl";
import { memo } from "react";
import Form from "antd/lib/form/Form";

import { useLessonPicker } from "./hooks";
import { LessonPickerProps } from "./types";

const LessonPicker: React.FC<LessonPickerProps> = ({ path }) => {
  const {
    form,
    initialValues,
    teachersLoading,
    subjectsLoading,
    subjects,
    selectedSubjectId,
    availableTeachers,
    classrooms,
    classroomsLoading,
    onChange
  } = useLessonPicker(path);

  return (
    <Form
      {...{ form, initialValues }}
      onFieldsChange={onChange}
      style={{ display: "flex" }}
      labelCol={{ span: 24, md: 12, lg: 8, xl: 6 }}
      wrapperCol={{ span: 24, md: 12, lg: 16, xl: 18 }}
    >
      <div className="timetable-lesson-subject">
        <FormItem
          name="subjectRefId"
          className="timetable-form-field"
          label={<FormattedMessage defaultMessage="Przedmiot" />}
        >
          <Select
            style={{ width: "100%" }}
            showSearch
            disabled={subjectsLoading}
            loading={subjectsLoading}
            options={subjects.map((subject) => ({
              value: subject.id,
              label: subject.name
            }))}
            filterOption={(input, option) =>
              (option?.label ?? "")
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          />
        </FormItem>
        <FormItem
          name="group"
          className="timetable-form-field"
          label={<FormattedMessage defaultMessage="Grupa" />}
        >
          <Input style={{ width: "100%" }} />
        </FormItem>
      </div>
      <div className="timetable-lesson-teacher">
        <FormItem
          name="teacherRefId"
          className="timetable-form-field"
          label={<FormattedMessage defaultMessage="Nauczyciel" />}
        >
          <Select
            style={{ width: "100%" }}
            showSearch
            disabled={teachersLoading || !selectedSubjectId}
            loading={teachersLoading}
            options={availableTeachers.map((teacher) => ({
              value: teacher.id,
              label: teacher.name
            }))}
            filterOption={(input, option) =>
              (option?.label ?? "")
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          />
        </FormItem>
        <FormItem
          name="classroom"
          className="timetable-form-field"
          label={<FormattedMessage defaultMessage="Sala" />}
        >
          <Select
            style={{ width: "100%" }}
            showSearch
            disabled={classroomsLoading}
            loading={classroomsLoading}
            options={classrooms.map((classroom) => ({
              value: classroom.name,
              label: classroom.name
            }))}
            filterOption={(input, option) =>
              (option?.label ?? "")
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          />
        </FormItem>
      </div>
    </Form>
  );
};

export default memo(LessonPicker);
