import { FIRESTORE_COLLECTIONS } from "~setup/consts/firestoreCollections";

import {
  makeFirestoreCollectionMutationHook,
  makeFirestoreDocumentDeletionHook,
  makeFirestoreDocumentHook,
  makeFirestoreDocumentMutationHook,
  makeFirestoreQueryHook
} from "../utils";

import { SubjectDocument } from "./types";

const useGetSubjectsQuery = makeFirestoreQueryHook<SubjectDocument>(
  FIRESTORE_COLLECTIONS.Subjects
);

const useGetSubjectQuery = makeFirestoreDocumentHook<SubjectDocument>(
  FIRESTORE_COLLECTIONS.Subjects
);

const useCreateSubjectMutation =
  makeFirestoreCollectionMutationHook<SubjectDocument>(
    FIRESTORE_COLLECTIONS.Subjects
  );

const useUpdateSubjectMutation =
  makeFirestoreDocumentMutationHook<SubjectDocument>(
    FIRESTORE_COLLECTIONS.Subjects
  );

const useSubjectDeletion = makeFirestoreDocumentDeletionHook(
  FIRESTORE_COLLECTIONS.Subjects
);

export {
  useGetSubjectsQuery,
  useGetSubjectQuery,
  useCreateSubjectMutation,
  useUpdateSubjectMutation,
  useSubjectDeletion
};
